export interface PackageInterface {
  _id: string;
  title: string;
  helpText: string;
  isActive: number;
  maxPeople: number;
  maxChild: number;
  maxBigAndMediumDogs: number;
  bigAndMediumDogExtraPrice: number;
  smallDogExtraPrice: number;
  maxSmallDogs: number;
  price: number;
  file: packagePhotos[];
  roomCategory: string;
  inventory: number;
  timeSlot: TIME_SLOT_TYPE_ENUM;
  created_at: string;
  updated_at: string;
  numberOfChildren: number;
  numberOfPeople: number;
  numberOfBigAndMediumDogs: number;
  numberOfSmallDogs: number;
  weekendPrice: number;
  dogExtraPrice: number;
  peopleExtraPrice: number;
  childrenExtraPrice: number;
  orderBy: number;

  smallDogsEnable: number;
  bigAndMediumDogsEnable: number;
  seasonPrice: TimeSlotInterface[];
  seasonPriceform: TimeSlotInterface;
  addOns: AddOnSectionInterface[];
  addOnsform: AddOnSectionInterface;
  dogType: DogTypeEnum;
  dogsEnable: number;
  numberOfDogs: number;
  maxDogs: number;
  newFile: string;
  bigAndMediumDogWeekEndExtraPrice: number;
  smallDogExtraWeekEndPrice: number;
  peopleWeekEndExtraPrice: number;
  childrenWeekEndExtraPrice: number;

  //
  noOfDogs: number;
  maxNoOfDogs: number;
  dogsExtraPrice: number;
  dogsWeekendPrice: number;
  // priceFor: PriceForAllEnum;
}

export enum PriceForAllEnum {
  INDIVIDUAL = 1,
  ALL = 2
}
export enum DogTypeEnum {
  BIG_AND_MEDIUM_DOG = 1,
  SMALL_DOG = 2,
  BOTH = 3,
  COMBINE_DOGS = 4
}
export interface TimeSlotInterface {
  _id: string;
  id: string;
  seasonId: string;
  seasonTitle: string;
  weekDayPrice: number;
  weekEndPrice: number;
  dogExtraPrice: number;
  peopleExtraPrice: number;
  childrenExtraPrice: number;
  action: string;
}
export interface AddOnSectionInterface {
  _id: string;
  id: string;
  title: string;
  price: number;
  maxPeople: number;
  minPeople: number;
  type: PackageAddOnTypeEum;
  action: string;
  noOfAddOnPerDay: number;
  image?: string;
  description?: string;
}

export enum PackageAddOnTypeEum {
  PACKAGE_BASED = 1,
  PEOPLE_BASED = 2
}
export interface packagePhotos {
  photo: string;
  fileName: string;
}
export enum BlogStatusEnum {
  ACTIVE = 1,
  INACTIVE = 2
}
export enum TIME_SLOT_TYPE_ENUM {
  SINGLE = 1,
  MULTIPLE = 2
}
export interface PackageState {
  PackageList: PackageInterface[];
  PackageFormData: PackageInterface;
  loading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  addOnsEditIndex: number;
}

export type InitialMileStoneStata = PackageState;
