import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { SiteSettingInterface, SettingState } from '../types';

export const initialState: SettingState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  settingList: [],
  settingFormData: {
    _id: '',
    picnicPrice: 0,
    minPicnicPeople: 0,
    maxPicnicPeople: 0
  },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'settingState',
  initialState,
  reducers: {
    updateSettingFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `settingFormData.${action.payload.key}`, action.payload.value);
    },
    AddSiteSetting: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateSiteSetting: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearSiteSettingFrom: (state) => {
      state.settingFormData._id = '';
      state.settingFormData.picnicPrice = 0;
      state.settingFormData.maxPicnicPeople = 0;
      state.settingFormData.minPicnicPeople = 0;
    },

    doSiteSettingDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doSiteSettingEdit: (state, action: PayloadAction<any>) => {},
    doGetSiteSettingList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSettingList: (state, action: PayloadAction<Array<SiteSettingInterface>>) => {
      state.settingList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSiteSettingForEdit: (state, action: PayloadAction<SiteSettingInterface>) => {
      state.settingFormData = action.payload;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
