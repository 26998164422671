import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { TopicInterface, NewsTopicsState } from '../types';
export const initialState: NewsTopicsState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  imgCoverLoading: false,
  imgLoading: false,
  FormData: {
    id: '',
    coverImg: '',
    coverImgfile: '',
    thumbImg: '',
    thumbImgFile: '',
    shortDescription: '',
    longDescription: '',
    catgoryId: '',
    date: '',
    title: '',
    _id: '',
    category:""
  },
  sortColumn: '',
  sortDir: '',
  List: []
};

export const useClinicSlice = createSlice({
  name: 'newsTopics',
  initialState,
  reducers: {
    updateFormDataFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `FormData.${action.payload.key}`, action.payload.value);
    },

    clearFormDataFrom: (state) => {
      state.FormData.id = '';
      state.FormData.coverImg = '';
      state.FormData.coverImgfile = '';
      state.FormData.thumbImg = '';
      state.FormData.thumbImgFile = '';
      state.FormData.title = '';
      state.FormData.shortDescription = '';
      state.FormData.longDescription = '';
      state.FormData.date = '';
      state.FormData.category="";
    },

    setSeasonTimeForEdit: (state, action: PayloadAction<TopicInterface>) => {
      state.FormData = action.payload;
      state.FormData.longDescription= action.payload.longDescription;
    },
    doAddImages: (state, action: PayloadAction<{ data: any; type: number; callback: any }>) => {},
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; callback: any }>) => {},
    setCoverPhotoData: (state, action: PayloadAction<{ fileName: string; photo: string }>) => {
      state.FormData.coverImgfile = action.payload.photo;
      state.FormData.coverImg = action.payload.fileName;
    },
    setThumbPhotoData: (state, action: PayloadAction<{ fileName: string; photo: string }>) => {
      state.FormData.thumbImgFile = action.payload.photo;
      state.FormData.thumbImg = action.payload.fileName;
    },

    AddGallery: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    doDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    setForEdit: (state, action: PayloadAction<TopicInterface>) => {
      state.FormData = action.payload;
    },
    doEdit: (state, action: PayloadAction<any>) => {},
    doGallaryEditWithID: (state, action: PayloadAction<{ id: any; callback: any }>) => {},
    doGetList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setList: (state, action: PayloadAction<Array<TopicInterface>>) => {
      state.List = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setImgLoading: (state, action: PayloadAction<boolean>) => {
      state.imgLoading = action.payload;
    },
    setCoverImgLoading: (state, action: PayloadAction<boolean>) => {
      state.imgCoverLoading = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
