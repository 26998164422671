import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import {
  DeleteDateOfAvaibilityRequest,
  GetAvailabilityByIdRequest,
  doGetPackageAvailabilitylist,
  doAddPackageAvailability,
  doUpdatePackageAvailability,
  doGetPackageList,
  doSearchPackageAvailability
} from 'utils copy/request';
import { PackageAvailabilityInterface } from '../types';
import { SelectPageNo, SelectPageSize, SelectSearch, selectPackageAvailabilityFormData, SelectSortColumn, SelectSortDir } from './selector';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import moment from 'moment';

export function* doAddPackageAvailabilityRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: PackageAvailabilityInterface = yield select(selectPackageAvailabilityFormData);

  if (form.packageId.length == 0) {
    openSnackbar({
      open: true,
      message: 'please select package',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });
    return;
  }

  if (form.startDate == null) {
    openSnackbar({
      open: true,
      message: 'please fill start date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: true
    });
    return;
  }
  if (form.endDate == null) {
    openSnackbar({
      open: true,
      message: 'please fill end date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: true
    });
    return;
  }
  try {
    const response: AxiosResponse = yield call(doAddPackageAvailability, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetPackageAvailabilityRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetPackageAvailabilitylist,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setPackageAvailabilityList(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doDeleteDateOfAvaiability(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Blog to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(DeleteDateOfAvaibilityRequest, action.payload.id);

    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getAvaiabilityByIdRequest(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Offer to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }
  try {
    const response: AxiosResponse = yield call(GetAvailabilityByIdRequest, action.payload);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }

    yield put(actions.setPackageAvailabilityForEdit(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdatePackageAvailabilityRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: PackageAvailabilityInterface = yield select(selectPackageAvailabilityFormData);

  if (form.startDate == null) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill date',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  
  if (form.endDate == null) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill date',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  try {
    const response: AxiosResponse = yield call(doUpdatePackageAvailability, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetPackageListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetPackageList);
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setPackageList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
// do get multi package
export function* doSearchMutliPackageRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: PackageAvailabilityInterface = yield select(selectPackageAvailabilityFormData);
  if (form.startDate == null) {
    openSnackbar({
      open: true,
      message: 'please fill start date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: true
    });
    return;
  }
const startDate=moment(form.startDate).toISOString()
  try {
    const response: AxiosResponse = yield call(doSearchPackageAvailability, `?startDate=${startDate}`);
   
    if (response && !response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield put(actions.setMultiPackageAvailabilityList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) { 
    CatchBlockFunction(error);
  }
}
export function* authRepoSagadateSelect() {
  yield takeLatest(actions.doAddPackageAvailability, doAddPackageAvailabilityRequest);
  yield takeLatest(actions.doGetPackageAvailabilityList, doGetPackageAvailabilityRequest);
  yield takeLatest(actions.doPackageAvailabilityDelete, doDeleteDateOfAvaiability);
  yield takeLatest(actions.doAvaiabilityById, getAvaiabilityByIdRequest);
  yield takeLatest(actions.doUpdatePackageAvailabilitySlice, doUpdatePackageAvailabilityRequest);
  yield takeLatest(actions.doGetPackageList, doGetPackageListRequest);
  yield takeLatest(actions.doAskMultiSummer, doSearchMutliPackageRequest);
}
