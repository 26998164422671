import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { GalleryInterface, GalleryState } from '../types';
export const initialState: GalleryState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  GalleryFormData: {
    featured: false,
    id: '',
    file: '',
    fileName: '',
    title: '',
    _id: ''
  },
  sortColumn: '',
  sortDir: '',
  GalleryList: []
};

export const useClinicSlice = createSlice({
  name: 'GalleryState',
  initialState,
  reducers: {
    updateGalleryFormDataFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `GalleryFormData.${action.payload.key}`, action.payload.value);
    },

    clearGalleryFormDataFrom: (state) => {
      state.GalleryFormData.id = '';
      state.GalleryFormData.featured = false;
      state.GalleryFormData.file = '';
      state.GalleryFormData.title = '';
      state.GalleryFormData.fileName = '';
    },

    setSeasonTimeForEdit: (state, action: PayloadAction<GalleryInterface>) => {
      state.GalleryFormData = action.payload;
    },
    doAddImages: (state, action: PayloadAction<{ data: any; type: number; callback: any }>) => {},
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; callback: any }>) => {},
    setPhotoData: (state, action: PayloadAction<{ fileName: string; photo: string }>) => {
      state.GalleryFormData.file = action.payload.photo;
      state.GalleryFormData.fileName = action.payload.fileName;
    },

    AddGallery: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateGallary: (state, action: PayloadAction<{ callback: () => void }>) => {},

    doGallaryDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    setGalleryForEdit: (state, action: PayloadAction<GalleryInterface>) => {
      state.GalleryFormData = action.payload;
    },
    doGallaryEdit: (state, action: PayloadAction<any>) => {},
    doGallaryEditWithID: (state, action: PayloadAction<{ id: any; callback: any }>) => {},
    doGetGallaryList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setGallary: (state, action: PayloadAction<Array<GalleryInterface>>) => {
      state.GalleryList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
