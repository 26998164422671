import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { selectLoginForm } from './selector';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { LoginRequest, MeProfileRequest } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';

// const navigate = useNavigate();

export function* meProfileRequest() {
  yield delay(500);
  if (localStorage.getItem('token')) {
    try {
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.data) {
        // console.log(response.data.message);
        return;
      }

      yield put(
        actions.setProfileData({
          id: response.data.data._id,
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          email: response.data.data.email,
          usertype: response.data.data.role
        })
      );

      yield put(actions.setisLogin(true));
    } catch (error: any) {
      localStorage.removeItem('token');
      yield put(actions.setisLogin(false)); //setisLogin for Now
      yield put(actions.setProfileData({ id: '', firstName: '', lastName: '', email: '', usertype: '' }));
    }
  } else {
    yield put(actions.setisLogin(false)); //setisLogin for Now
  }
}
export function* meLogOutRequest() {
  yield delay(500);
  localStorage.removeItem('token');
  yield put(actions.setisLogin(false)); //setisLogin for Now
  yield put(actions.setProfileData({ id: '', firstName: '', lastName: '', email: '', usertype: '' }));
}
export function* loginRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const { username, password } = yield select(selectLoginForm);
  if (username.length === 0) {
    openSnackbar({
      open: true,
      message: 'Please enter username',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });
    return;
  }
  if (password.length === 0) {
    openSnackbar({
      open: true,
      message: 'Please enter password',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });
    return;
  }

  let data = {
    email: username,
    password: password
  };
  try {
    const response: AxiosResponse = yield call(LoginRequest, data);

    if (response && !response.data.statusCode) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }
    yield put(actions.setUpdateToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
    yield put(actions.loginSuccess());

    // console.log(response.data.message);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];
          openSnackbar({
            open: true,
            message: element.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          });
        }
      } else {
        openSnackbar({
          open: true,
          message: error.response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
      }
    } else {
      openSnackbar({
        open: true,
        message: error.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
    }
  }
}

export function* authRepoSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.doLogin, loginRequest);
  yield takeLatest(actions.getUserProfile, meProfileRequest);
  yield takeLatest(actions.doLogOut, meLogOutRequest);
}
