// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CalendarOutlined, DashboardOutlined, GoldOutlined, HomeOutlined, UserOutlined  ,QuestionCircleOutlined , AudioOutlined , TagOutlined , DisconnectOutlined ,BellOutlined , MediumOutlined} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined, 
  UserOutlined , 
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined, 
  TagOutlined,
  DisconnectOutlined , 
  BellOutlined,
  MediumOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const refundteam: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [  

      {
        id: 'refund',
        title: <FormattedMessage id="Refund" />,
        type: 'collapse',
        icon: icons.BellOutlined,
        children: [
          {
            id: 'product-list',
            title: <FormattedMessage id="Refund list" />,
            type: 'item',
            url: '/refund/refundlist',
            breadcrumbs: false
          },
          {
            id: 'add-new-product',
            title: <FormattedMessage id="Recent Refund" />,
            type: 'item',
            url: '/refund/recentrefund'
          }
        ]
      },
  
      {
        id: 'ticket-status',
        title: <FormattedMessage id="Ticket Information" />,
        type: 'item',
        icon: icons.CalendarOutlined,
        url: '/ticket/ticketinfo',
      },


  ]
};

export default refundteam;
