import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { RoomCategoryInterface, RoomCategoryState, StatusTypeEnum } from '../types';

export const initialState: RoomCategoryState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  roomCategoryList: [],
  roomCategoryFormData: {
    id: '',
    _id: '',
    title: '',
    isActive: StatusTypeEnum.ACTIVE
  },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'roomCategoryState',
  initialState,
  reducers: {
    updateRoomCategoryFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `roomCategoryFormData.${action.payload.key}`, action.payload.value);
    },
    AddRoomCategory: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateRoomCategory: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearRoomCategoryrom: (state) => {
      state.roomCategoryFormData.id = '';
      state.roomCategoryFormData._id = '';
      state.roomCategoryFormData.title = '';
      state.roomCategoryFormData.isActive = StatusTypeEnum.ACTIVE;
    },
    doPackageDelete: (state, action: PayloadAction<any>) => {},
    doRoomCategoryDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doRoomCategoryEdit: (state, action: PayloadAction<any>) => {},
    doGetRoomCategoryList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setRoomCategoryList: (state, action: PayloadAction<Array<RoomCategoryInterface>>) => {
      state.roomCategoryList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setRoomCategoryForEdit: (state, action: PayloadAction<RoomCategoryInterface>) => {
      state.roomCategoryFormData = action.payload;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
