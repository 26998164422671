import { dispatch } from 'store';
import errorCode from './errorCode';
import { openSnackbar } from 'store/reducers/snackbar';

export default function CatchBlockFunction(error: any) { 
  
  if (errorCode(error?.response?.data.statusCode)) {
    dispatch(
      openSnackbar({
        open: true,
        message: error?.response?.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
  } else {
    // dispatch(
    //   openSnackbar({
    //     open: true,
    //     message: error?.response?.data?.message,
    //     variant: 'alert',
    //     alert: {
    //       color: 'error'
    //     },
    //     close: true
    //   })
    // );
  }

  if (error.message !== "Cannot read properties of undefined (reading 'context')") {
    if (error.message !== "undefined is not an object (evaluating 'e.context')") {
      if (error.message !== 'e is undefined') {
        if (error.message !== "Cannot read properties of undefined (reading '0')") {
          if (error.message !== 'call: argument fn is undefined or null') {
            if (error.message !== "Cannot read properties of undefined (reading 'results')") {
              if (error.message !== "Cannot set properties of undefined (setting 'seasonId')") {
                if (error.message !== "Cannot set properties of undefined (setting '_id')") {
                  if (error?.response?.data) {
                    if (error?.response?.data.statusCode !== 401) {
                      if (error?.response?.data.errors) {
                        let dataerrer = error?.response?.data?.errors?.map((item: any) => {
                          return {
                            name: item?.property,
                            message: item?.message
                          };
                        });
                        for (let index = 0; index < dataerrer.length; index++) {
                          const element = dataerrer[index];

                          dispatch(
                            openSnackbar({
                              open: true,
                              message: element?.message,
                              variant: 'alert',
                              alert: {
                                color: 'error'
                              },
                              close: true
                            })
                          );
                        }
                        let dataerrer1 = error?.response?.data.errors.map((item: any) => {
                          return {
                            name: item?.property,
                            message: item?.constraints?.isEmail
                          };
                        });
                        for (let index = 0; index < dataerrer1.length; index++) {
                          const element = dataerrer1[index];

                          dispatch(
                            openSnackbar({
                              open: true,
                              message: element.message,
                              variant: 'alert',
                              alert: {
                                color: 'error'
                              },
                              close: true
                            })
                          );
                        }
                      } else {
                        dispatch(
                          openSnackbar({
                            open: true,
                            message: error?.response?.data?.message,
                            variant: 'alert',
                            alert: {
                              color: 'error'
                            },
                            close: true
                          })
                        );
                      }
                    } else {
                    }
                  } else {
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: error.message,
                        variant: 'alert',
                        alert: {
                          color: 'error'
                        },
                        close: true
                      })
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
