export enum StatusTypeEnum {
  ACTIVE = 1,
  INACTIVE = 2
}

export interface SeasonAddInterface {
  _id: string;
  id: string;
  title: string;
  isActive: StatusTypeEnum;
  startDate: Date;
  endDate: Date;
  timeSlot: TimeSlotInterface[];
  timeSlotform: TimeSlotInterface;
}
export interface TimeSlotInterface {
  id: string;
  startTime: any;
  endTime: any;
}
export interface SeasonState {
  seasonList: SeasonAddInterface[];
  seasonFormData: SeasonAddInterface;
  loading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialSeasonState = SeasonState;
