import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'NewTypes/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.AuthState) {
      return state.AuthState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};
export const selectLoginForm = createSelector([selectDomain], (state) => state.loginFrom);
export const selectIsLogin = createSelector([selectDomain], (state) => state.isLogin);
export const selectUserData = createSelector([selectDomain], (state: any) => state.userData);
export const selectUserType = createSelector([selectDomain], (state) => state.userData.usertype);
