import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.packageAvailability;
  } else {
    return initialState;
  }
};

export const selectPackageAvailabilityFormData = createSelector([selectDomain], (state) => state.packageAvailabilityFormData);
export const selectPackageAvailabilityList = createSelector([selectDomain], (state) => state.packageAvailabilityList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectPackageList = createSelector([selectDomain], (state) => state.packageList);
export const SelectPackageMultiAvailability = createSelector([selectDomain], (state) => state.packageMultiAvailability);
export const selectBookingPackageAddList = createSelector([selectDomain], (state) => state.packageAvailabilityFormData.multiTimeSlot);
