// import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import
// import CommonLayout from 'layout/CommonLayout';
// import Loadable from 'components/Loadable';
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';


// project import
// import GuestGuard from 'utils/route-guard/GuestGuard';

// // render - login
// const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
// const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
// render - landing page

// const DefaultPath = Loadable(lazy(() => import('pages/dashboard/default')));

// const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //


export default function ThemeRoutes() {
  return useRoutes([
    // {
    //   path: '/',
    //   element: <CommonLayout layout="landing" />,
    //   children: [
    //     {
    //       path: '/',
    //       element: <PagesLanding />
    //     }
    //   ]
    // },
    ComponentsRoutes,
    MainRoutes,
    LoginRoutes,
   
  ]);
}
