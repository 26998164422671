import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { AddOnSectionInterface, DogTypeEnum, PackageAddOnTypeEum, PackageInterface, PackageState, TIME_SLOT_TYPE_ENUM, TimeSlotInterface } from '../types';

export const initialState: PackageState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  PackageList: [],
  PackageFormData: {
    _id: '',
    title: '',
    helpText: '',
    maxPeople: 0,
    maxChild: 0,
    maxBigAndMediumDogs: 0,
    maxSmallDogs: 0,
    smallDogExtraPrice: 0,
    bigAndMediumDogExtraPrice: 0,
    inventory: 0,
    price: 0,
    file: [],
    timeSlot: TIME_SLOT_TYPE_ENUM.SINGLE,
    created_at: '',
    updated_at: '',
    roomCategory: '',
    numberOfChildren: 0,
    numberOfPeople: 0,
    numberOfBigAndMediumDogs: 0,
    numberOfSmallDogs: 0,
    weekendPrice: 0,
    dogExtraPrice: 0,
    peopleExtraPrice: 0,
    childrenExtraPrice: 0,
    orderBy: 0,
    newFile: '',
    bigAndMediumDogsEnable: 0,
    smallDogsEnable: 0,
    seasonPriceform: {
      _id: '',
      id: '',
      seasonId: '',
      seasonTitle: '',
      weekDayPrice: 0,
      weekEndPrice: 0,
      dogExtraPrice: 0,
      peopleExtraPrice: 0,
      childrenExtraPrice: 0,
      action: ''
    },
    seasonPrice: [],
    addOns: [],
    addOnsform: {
      id: '',
      _id: '',
      title: '',
      price: 0,
      maxPeople: 0,
      minPeople: 0,
      type: PackageAddOnTypeEum.PEOPLE_BASED,
      action: '',
      description:"",
      image:"",
      noOfAddOnPerDay: 0,
    },
    dogsEnable: 0,
    numberOfDogs: 0,
    maxDogs: 0,
    dogType: DogTypeEnum.SMALL_DOG,
    bigAndMediumDogWeekEndExtraPrice: 0,
    smallDogExtraWeekEndPrice: 0,
    peopleWeekEndExtraPrice: 0,
    childrenWeekEndExtraPrice: 0,
    isActive: 0,
    noOfDogs: 0,
    maxNoOfDogs: 0,
    dogsExtraPrice: 0,
    dogsWeekendPrice: 0
    // priceFor: PriceForAllEnum.INDIVIDUAL
  },
  sortColumn: '',
  sortDir: '',addOnsEditIndex: -1,
};

export const useClinicSlice = createSlice({
  name: 'packageState',
  initialState,
  reducers: {
    updateSeasonTimeFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `PackageFormData.timeSlotform.${action.payload.key}`, action.payload.value);
    },
    AddSeasonTime: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.PackageFormData.seasonPrice.push(state.PackageFormData.seasonPriceform);
    },
   
    clearSeasonTimeFrom: (state) => {
      state.PackageFormData.seasonPriceform._id = '';
      state.PackageFormData.seasonPriceform.id = '';
      state.PackageFormData.seasonPriceform.seasonId = '';
      state.PackageFormData.seasonPriceform.seasonTitle = '';
      state.PackageFormData.seasonPriceform.weekDayPrice = 0;
      state.PackageFormData.seasonPriceform.weekEndPrice = 0;
      state.PackageFormData.seasonPriceform.dogExtraPrice = 0;
      state.PackageFormData.seasonPriceform.peopleExtraPrice = 0;
      state.PackageFormData.seasonPriceform.childrenExtraPrice = 0;
    },
    clearAddOnSectionFrom: (state) => {
      state.PackageFormData.addOnsform._id = '';
      state.PackageFormData.addOnsform.id = '';
      state.PackageFormData.addOnsform.title = '';
      state.PackageFormData.addOnsform.price = 0;
      state.PackageFormData.addOnsform.minPeople = 0;
      state.PackageFormData.addOnsform.maxPeople = 0;
      state.PackageFormData.addOnsform.action = '';
      state.PackageFormData.addOnsform.type = PackageAddOnTypeEum.PEOPLE_BASED;
      state.PackageFormData.addOnsform.noOfAddOnPerDay = 0;
      state.PackageFormData.addOnsform.description = "";
      state.PackageFormData.addOnsform.image = "";
    },
    setSeasonTimeForEdit: (state, action: PayloadAction<TimeSlotInterface>) => {
      state.PackageFormData.seasonPriceform = action.payload;
    },
    doAddImages: (state, action: PayloadAction<{ data: any; type: number; callback: any }>) => {},
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; callback: any }>) => {},
    setPhotoData: (state, action: PayloadAction<{ fileName: string; photo: string }>) => {
      state.PackageFormData.file.push({
        fileName: action.payload.fileName,
        photo: action.payload.photo
      });
    },
    updatePackageFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `PackageFormData.${action.payload.key}`, action.payload.value);
    },
    updatePackageAddOnFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `PackageFormData.addOnsform.${action.payload.key}`, action.payload.value);
    },
    AddPackage: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdatePackage: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearPackageFrom: (state) => {
      state.PackageFormData.title = '';
      state.PackageFormData.helpText = '';
      state.PackageFormData.maxPeople = 0;
      state.PackageFormData.maxChild = 0;
      state.PackageFormData.price = 0;
      state.PackageFormData.childrenExtraPrice = 0;
      state.PackageFormData.orderBy = 0;
      state.PackageFormData.maxBigAndMediumDogs = 0;
      state.PackageFormData.maxSmallDogs = 0;
      state.PackageFormData.inventory = 0;
      state.PackageFormData.roomCategory = '';
      state.PackageFormData.timeSlot = TIME_SLOT_TYPE_ENUM.SINGLE;
      state.PackageFormData.numberOfChildren = 0;
      state.PackageFormData.numberOfBigAndMediumDogs = 0;
      state.PackageFormData.numberOfPeople = 0;
      state.PackageFormData.numberOfSmallDogs = 0;
      state.PackageFormData.weekendPrice = 0;
      state.PackageFormData.dogExtraPrice = 0;
      state.PackageFormData.peopleExtraPrice = 0;
      state.PackageFormData.bigAndMediumDogExtraPrice = 0;
      state.PackageFormData.smallDogExtraPrice = 0;
      state.PackageFormData.file = [];
      state.PackageFormData.smallDogsEnable = 0;
      state.PackageFormData.bigAndMediumDogsEnable = 0;
      state.PackageFormData.seasonPrice = [];
      state.PackageFormData.seasonPriceform._id = '';
      state.PackageFormData.seasonPriceform.seasonId = '';
      state.PackageFormData.seasonPriceform.childrenExtraPrice = 0;
      state.PackageFormData.seasonPriceform.dogExtraPrice = 0;
      state.PackageFormData.seasonPriceform.peopleExtraPrice = 0;
      state.PackageFormData.seasonPriceform.seasonTitle = '';
      state.PackageFormData.seasonPriceform.weekDayPrice = 0;
      state.PackageFormData.seasonPriceform.weekEndPrice = 0;
      state.PackageFormData.addOns = [];
      state.PackageFormData.timeSlot = TIME_SLOT_TYPE_ENUM.SINGLE;
      state.PackageFormData.bigAndMediumDogWeekEndExtraPrice = 0;
      state.PackageFormData.smallDogExtraWeekEndPrice = 0;
      state.PackageFormData.peopleWeekEndExtraPrice = 0;
      state.PackageFormData.childrenWeekEndExtraPrice = 0;
      state.PackageFormData.dogsWeekendPrice = 0;
      state.PackageFormData.dogsExtraPrice = 0;
      state.PackageFormData.maxNoOfDogs = 0;
      state.PackageFormData.noOfDogs = 0;
    },
    doPackageDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doPackageEdit: (state, action: PayloadAction<any>) => {},
    doGetPackageList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPackageist: (state, action: PayloadAction<Array<PackageInterface>>) => {
      state.PackageList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPackageForEdit: (state, action: PayloadAction<PackageInterface>) => {
      state.PackageFormData = action.payload;
      state.PackageFormData.bigAndMediumDogWeekEndExtraPrice = action.payload.bigAndMediumDogWeekEndExtraPrice
        ? action.payload.bigAndMediumDogWeekEndExtraPrice
        : 0;
      state.PackageFormData.smallDogExtraWeekEndPrice = action.payload.smallDogExtraWeekEndPrice
        ? action.payload.smallDogExtraWeekEndPrice
        : 0;
      state.PackageFormData.peopleWeekEndExtraPrice = action.payload.peopleWeekEndExtraPrice ? action.payload.peopleWeekEndExtraPrice : 0;
      state.PackageFormData.childrenWeekEndExtraPrice = action.payload.childrenWeekEndExtraPrice
        ? action.payload.childrenWeekEndExtraPrice
        : 0;
      state.PackageFormData.noOfDogs = action.payload.noOfDogs ? action.payload.noOfDogs : 0;
      state.PackageFormData.maxNoOfDogs = action.payload.maxNoOfDogs ? action.payload.maxNoOfDogs : 0;
      state.PackageFormData.dogsExtraPrice = action.payload.dogsExtraPrice ? action.payload.dogsExtraPrice : 0;
      state.PackageFormData.dogsWeekendPrice = action.payload.dogsWeekendPrice ? action.payload.dogsWeekendPrice : 0;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },

    deleteAddOnDelete: (state, action: PayloadAction<number>) => {
      if (state.PackageFormData.addOns[action.payload]._id?.length > 0) {
        state.PackageFormData.addOns[action.payload].action = 'delete';
      } else {
        state.PackageFormData.addOns.splice(action.payload, 1);
      }
    },
    deletePhotos: (state, action: PayloadAction<number>) => {
      state.PackageFormData.file.splice(action.payload, 1);
    },
    deleteSeasonDelete: (state, action: PayloadAction<number>) => {
      if (state.PackageFormData.seasonPrice[action.payload]._id?.length > 0) {
        state.PackageFormData.seasonPrice[action.payload].action = 'delete';
      } else {
        state.PackageFormData.seasonPrice.splice(action.payload, 1);
      }
    },
    setDataAddOnForEdit: (
      state,
      action: PayloadAction<{ data: AddOnSectionInterface; index: number }>
    ) => {
      state.addOnsEditIndex = action.payload.index;
      state.PackageFormData.addOnsform = action.payload.data;
    },
    
    AddOnSection: (state, action: PayloadAction<{ callback: () => void }>) => {
      // state.PackageFormData.addOns.push(state.PackageFormData.addOnsform);
debugger
      if (state.addOnsEditIndex > -1) {
        state.PackageFormData.addOns[
          state.addOnsEditIndex
        ] = state.PackageFormData.addOnsform;
        state.addOnsEditIndex = -1;
      } else {
        state.PackageFormData.addOns.push(
          state.PackageFormData.addOnsform
        );
      }
      state.addOnsEditIndex = -1;
      
    },
  }  
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
