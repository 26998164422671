import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { HolidaysInterface, HolidaysState } from '../types';

export const initialState: HolidaysState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  sortColumn: '',
  sortDir: '',
  holidaysList: [],
  holidaysFormData: {
    _id: '',
    title: '',
    packageId: '',
    startDate: ''
  }
};

export const useClinicSlice = createSlice({
  name: 'holidaysState',
  initialState,
  reducers: {
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `holidaysFormData.${action.payload.key}`, action.payload.value);
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearForm: (state) => {
      state.holidaysFormData._id = '';
      state.holidaysFormData.title = '';
      state.holidaysFormData.packageId = '';
      state.holidaysFormData.startDate = '';
    },

    doDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doGetByEdit: (state, action: PayloadAction<any>) => {},
    doGetList: (state) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setList: (state, action: PayloadAction<Array<HolidaysInterface>>) => {
      state.holidaysList = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<HolidaysInterface>) => {
      state.holidaysFormData = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
