import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../NewTypes/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  console.log("state.CouponState", state);

  if (state) {
    return state.CouponState;
  } else {
    return initialState;
  }
};

// export const selectCouponForm = createSelector(
//   [selectDomain],
//   (state) => state.CouponFormData
// );
export const selectCouponForm:any = createSelector([selectDomain], (state) => state.CouponFormData)
export const selectCouponListData = createSelector(
  [selectDomain],
  (state) => state.CouponList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
