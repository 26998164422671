import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import moment from 'moment';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { SeasonAddInterface, SeasonState, StatusTypeEnum, TimeSlotInterface } from '../types';
const today: any = moment;
export const initialState: SeasonState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  seasonList: [],
  seasonFormData: {
    id: '',
    _id: '',
    title: '',
    isActive: StatusTypeEnum.ACTIVE,
    startDate: new Date(today),
    endDate: new Date(today),
    timeSlotform: { id: '', startTime: new Date(today), endTime: new Date(today) },
    timeSlot: []
  },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'seasonState',
  initialState,
  reducers: {
    doAddImages: (state, action: PayloadAction<{ data: any; type: number; callback: any }>) => {},

    AddSeasonTime: (state, action: PayloadAction<{ stateTime: Date; endTime: Date; callback: () => void }>) => {
      state.seasonFormData.timeSlot.push({ id: '', startTime: action.payload.stateTime, endTime: action.payload.endTime });
    },
    updateSeasonFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `seasonFormData.${action.payload.key}`, action.payload.value);
    },
    updateSeasonTimeFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `seasonFormData.timeSlotform.${action.payload.key}`, action.payload.value);
    },
    AddSeason: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateSeason: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearSeasonFrom: (state) => {
      state.seasonFormData.id = '';
      state.seasonFormData.title = '';
      state.seasonFormData.timeSlot = [];
      state.seasonFormData.isActive = StatusTypeEnum.ACTIVE;
    },
    clearSeasonTimeFrom: (state) => {
      state.seasonFormData.timeSlotform.id = '';
      state.seasonFormData.timeSlotform.startTime = today;
      state.seasonFormData.timeSlotform.endTime = today;
    },

    doSeasonDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doSeasonEdit: (state, action: PayloadAction<any>) => {},
    doGetSeasonList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSeasonist: (state, action: PayloadAction<Array<SeasonAddInterface>>) => {
      state.seasonList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSeasonForEdit: (state, action: PayloadAction<SeasonAddInterface>) => {
      state.seasonFormData = action.payload;
    },
    setSeasonTimeForEdit: (state, action: PayloadAction<TimeSlotInterface>) => {
      state.seasonFormData.timeSlotform = action.payload;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    deleteTimeDelete: (state, action: PayloadAction<number>) => {
      state.seasonFormData.timeSlot.splice(action.payload, 1);
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
