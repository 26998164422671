import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state && state.GalleryState) {
    return state.GalleryState;
  } else {
    return initialState;
  }
};

export const selectGalleryForm = createSelector([selectDomain], (state) => state.GalleryFormData);
export const selectGalleryList = createSelector([selectDomain], (state) => state.GalleryList);
export const selectGalleryPhotoForm = createSelector([selectDomain], (state) => state.GalleryFormData.file);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
