import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import {
  BookingCancelInterface,
  BookingDateInterface,
  BookingInterface,
  BookingPendingInterface,
  BookingState,
  BookingStatusType,
  HasDog,
  TIME_SLOT_TYPE_ENUM
} from '../types';
import { set } from 'lodash';

export const initialState: BookingState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  pageSizeCancel: 10,
  totalRowCancel: 0,
  search: '',
  pageNo: 1,
  pageNoCancel: 1,
  bookingList: [],
  bookingCancelList: [],
  bookingCancelFormData: {
    packageTitles: [],
    _id: '',
    englishFirstName: '',
    paymentMethod: 0,
    englishLastName: '',
    firstName: '',
    lastName: '',
    userId: '',
    payment_id: '',
    order_status: 0,
    payment_status: 0,
    totalPrice: 0,
    people: 0,
    child: 0,
    bigAndMediumDogs: 0,
    smallDogs: 0,
    totalDogs: 0,
    amount_received: 0,
    payment_getway_status: false,
    createdAt: '',
    updatedAt: '',
    status: BookingStatusType.Booked,
    bookingId: '',
    cartId: '',
    packageID: '',
    title: '',
    helpText: '',
    dogs: 0,
    carPersonFirstName: '',
    carPersonLastName: '',
    carPersonEnglishFirstName: '',
    maxPeople: 0,
    maxChild: 0,
    quantity: 0,
    price: 0,
    inventory: 0,
    email: '',
    state: '',
    district: '',
    city: '',
    pincode: '',
    apartment: '',
    maxDogs: 0,
    bbq: 0,
    timeSlot: TIME_SLOT_TYPE_ENUM.MULTIPLE,
    roomCategory: '',
    date: '',
    startTime: new Date(),
    endTime: new Date(),
    sameAsUser: false,
    carPersonEmail: '',
    carPersonmobileNumber: '',
    mobileNumber: '',
    arrivalTime: '',
    carPersonEnglishLastName: '',
    dogsEnable: true,
    numberOfDogs: 0,
    picnic: 0,
    description: '',
    addOns: [],
    cartAddOn: [],
    startDate: '',
    endDate: '',
    user_dogs: [],
    hasDog: HasDog.NO
  },
  bookingFormData: {
    packageTitles: [],
    timeSlotIndex: 0,
    _id: '',
    paymentMethod: 0,
    englishFirstName: '',
    englishLastName: '',
    firstName: '',
    lastName: '',
    userId: '',
    payment_id: '',
    order_status: 0,
    payment_status: 0,
    totalPrice: 0,
    people: 0,
    child: 0,
    bigAndMediumDogs: 0,
    smallDogs: 0,
    totalDogs: 0,
    amount_received: 0,
    payment_getway_status: false,
    createdAt: '',
    updatedAt: '',
    status: BookingStatusType.Booked,
    bookingId: '',
    cartId: '',
    packageID: '',
    title: '',
    helpText: '',
    dogs: 0,
    carPersonFirstName: '',
    carPersonLastName: '',
    carPersonEnglishFirstName: '',
    maxPeople: 0,
    maxChild: 0,
    quantity: 0,
    price: 0,
    inventory: 0,
    email: '',
    state: '',
    district: '',
    city: '',
    pincode: '',
    apartment: '',
    maxDogs: 0,
    bbq: 0,
    timeSlot: TIME_SLOT_TYPE_ENUM.MULTIPLE,
    roomCategory: '',
    date: '',
    startTime: new Date(),
    endTime: new Date(),
    sameAsUser: false,
    carPersonEmail: '',
    carPersonmobileNumber: '',
    mobileNumber: '',
    arrivalTime: '',
    carPersonEnglishLastName: '',
    dogsEnable: true,
    numberOfDogs: 0,
    picnic: 0,
    description: '',
    addOns: [],
    cartAddOn: [],
    startDate: '',
    endDate: '',
    user_dogs: [],
    hasDog: HasDog.NO,
    pplExtraCharges: 0,
    childrenExtraCharges: 0,
    picnicCharges: 0,
    bigAndMediumDogExtraCharges: 0,
    smallDogExtraCharges: 0
  },
  bookingDateFormData: {
    packageTitles: [],
    timeSlotIndex: 0,
    _id: '',
    paymentMethod: 0,
    englishFirstName: '',
    englishLastName: '',
    firstName: '',
    lastName: '',
    userId: '',
    payment_id: '',
    order_status: 0,
    payment_status: 0,
    totalPrice: 0,
    people: 0,
    child: 0,
    bigAndMediumDogs: 0,
    smallDogs: 0,
    totalDogs: 0,
    amount_received: 0,
    payment_getway_status: false,
    createdAt: '',
    updatedAt: '',
    status: BookingStatusType.Booked,
    bookingId: '',
    cartId: '',
    packageID: '',
    title: '',
    helpText: '',
    dogs: 0,
    carPersonFirstName: '',
    carPersonLastName: '',
    carPersonEnglishFirstName: '',
    maxPeople: 0,
    maxChild: 0,
    quantity: 0,
    price: 0,
    inventory: 0,
    email: '',
    state: '',
    district: '',
    city: '',
    pincode: '',
    apartment: '',
    maxDogs: 0,
    bbq: 0,
    timeSlot: TIME_SLOT_TYPE_ENUM.MULTIPLE,
    roomCategory: '',
    date: '',
    startTime: new Date(),
    endTime: new Date(),
    sameAsUser: false,
    carPersonEmail: '',
    carPersonmobileNumber: '',
    mobileNumber: '',
    arrivalTime: '',
    carPersonEnglishLastName: '',
    dogsEnable: true,
    numberOfDogs: 0,
    picnic: 0,
    description: '',
    addOns: [],
    cartAddOn: [],
    startDate: '',
    endDate: '',
    user_dogs: [],
    hasDog: HasDog.NO,
    pplExtraCharges: 0,
    childrenExtraCharges: 0,
    picnicCharges: 0,
    bigAndMediumDogExtraCharges: 0,
    smallDogExtraCharges: 0
  },
  bookingDateList: [],
  sortColumn: '',
  sortDir: '',
  sortColumnCancel: '',
  sortDirCancel: '',
  sortColumnPending: '',
  sortDirPending: '',
  pageNoPending: 1,
  pageSizePending: 10,
  totalRowPending: 0,
  bookingPendingList: [],
  bookingPendingFormData: {
    packageTitles: [],
    paymentMethod: 0,
    _id: '',
    englishFirstName: '',
    englishLastName: '',
    firstName: '',
    lastName: '',
    userId: '',
    payment_id: '',
    order_status: 0,
    payment_status: 0,
    totalPrice: 0,
    people: 0,
    child: 0,
    bigAndMediumDogs: 0,
    smallDogs: 0,
    totalDogs: 0,
    amount_received: 0,
    payment_getway_status: false,
    createdAt: '',
    updatedAt: '',
    status: BookingStatusType.Booked,
    bookingId: '',
    cartId: '',
    packageID: '',
    title: '',
    helpText: '',
    dogs: 0,
    carPersonFirstName: '',
    carPersonLastName: '',
    carPersonEnglishFirstName: '',
    maxPeople: 0,
    maxChild: 0,
    quantity: 0,
    price: 0,
    inventory: 0,
    email: '',
    state: '',
    district: '',
    city: '',
    pincode: '',
    apartment: '',
    maxDogs: 0,
    bbq: 0,
    timeSlot: TIME_SLOT_TYPE_ENUM.MULTIPLE,
    roomCategory: '',
    date: '',
    startTime: new Date(),
    endTime: new Date(),
    sameAsUser: false,
    carPersonEmail: '',
    carPersonmobileNumber: '',
    mobileNumber: '',
    arrivalTime: '',
    carPersonEnglishLastName: '',
    dogsEnable: true,
    numberOfDogs: 0,
    picnic: 0,
    description: '',
    addOns: [],
    cartAddOn: [],
    startDate: '',
    endDate: '',
    user_dogs: [],
    hasDog: HasDog.NO
  },
  sortColumnDateBooking: '',
  sortDirDateBooking: '',
  pageNoDateBooking: 1,
  pageSizeDateBooking: 10,
  totalRowDateBooking: 0
};

export const useClinicSlice = createSlice({
  name: 'bookingState',
  initialState,
  reducers: {
    doGetBookingList: (state) => {},

    doGetCancelBookingList: (state) => {},
    doGetPendingBookingList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setTotalCancelRow: (state, action: PayloadAction<number>) => {
      state.totalRowCancel = action.payload;
    },
    setTotalPendingRow: (state, action: PayloadAction<number>) => {
      state.totalRowPending = action.payload;
    },
    getBookingById: (state, action: PayloadAction<{ id: string; callback: any }>) => {},

    setBookingList: (state, action: PayloadAction<Array<BookingInterface>>) => {
      state.bookingList = action.payload;
    },
    setCancelBookingList: (state, action: PayloadAction<Array<BookingCancelInterface>>) => {
      state.bookingCancelList = action.payload;
    },
    setPendingBookingList: (state, action: PayloadAction<Array<BookingPendingInterface>>) => {
      state.bookingPendingList = action.payload;
    },
    setBookingForEdit: (state, action: PayloadAction<Array<BookingInterface>>) => {
      state.bookingList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNoPending: (state, action: PayloadAction<number>) => {
      state.pageNoPending = action.payload;
    },
    setPageSizePending: (state, action: PayloadAction<number>) => {
      state.pageSizePending = action.payload;
    },
    setPageNoCancel: (state, action: PayloadAction<number>) => {
      state.pageNoCancel = action.payload;
    },
    setPageSizeCancel: (state, action: PayloadAction<number>) => {
      state.pageSizeCancel = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setSortColumnCancel: (state, action: PayloadAction<string>) => {
      state.sortColumnCancel = action.payload;
    },
    setSortDirCancel: (state, action: PayloadAction<string>) => {
      state.sortDirCancel = action.payload;
    },
    setSortColumnPending: (state, action: PayloadAction<string>) => {
      state.sortColumnPending = action.payload;
    },
    setSortDirPending: (state, action: PayloadAction<string>) => {
      state.sortDirPending = action.payload;
    },
    doBookingEdit: (state, action: PayloadAction<any>) => {},
    updateDateFilterFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `bookingFormData.${action.payload.key}`, action.payload.value);
    },

    updateFilterFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `bookingPendingFormData.${action.payload.key}`, action.payload.value);
    },
    updateCancelDateFilterFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `bookingCancelFormData.${action.payload.key}`, action.payload.value);
    },
    //
    updateBookingDateFilterFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `bookingDateFormData.${action.payload.key}`, action.payload.value);
    },
    setBookingDateList: (state, action: PayloadAction<Array<BookingDateInterface>>) => {
      state.bookingDateList = action.payload;
    },
    doGetBookingDateList: (state) => {},
    setBookingDatePageNo: (state, action: PayloadAction<number>) => {
      state.pageNoDateBooking = action.payload;
    }, //BookingDate
    setBookingDatePageSize: (state, action: PayloadAction<number>) => {
      state.pageSizeDateBooking = action.payload;
    },
    setSortColumnBookingDate: (state, action: PayloadAction<string>) => {
      state.sortColumnDateBooking = action.payload;
    },
    setSortDirBookingDate: (state, action: PayloadAction<string>) => {
      state.sortDirDateBooking = action.payload;
    },
    setTotalBookingDateRow: (state, action: PayloadAction<number>) => {
      state.totalRowDateBooking = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
