import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import { SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir, selectGalleryForm } from './selector';
import { GalleryInterface } from '../types';
import {
  doAddGallery,
  doDeleteGallery,
  doGetGalleryById,
  doGetGalleryListRequest,
  doUpdateGallery,
  getImageRequest,
  uplodeImageRequest
} from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* AddGalleryRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: GalleryInterface = yield select(selectGalleryForm);

  try {
    const response: AxiosResponse = yield call(doAddGallery, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updateGalleryRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: GalleryInterface = yield select(selectGalleryForm);

  if (form.file == '') {
    yield put(
      openSnackbar({
        open: true,
        message: 'please select package photos',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doUpdateGallery, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getGalleryListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      doGetGalleryListRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setGallary(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGalleryDeleteRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Blog to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doDeleteGallery, action.payload.id);

    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getGalleryById(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select User',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doGetGalleryById, action.payload);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }

    yield put(actions.setGalleryForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getGalleryByIdFeature(action: { payload: { id: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetGalleryById, action.payload.id);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(actions.setGalleryForEdit(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getImageRequest, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(uplodeImageRequest, action.payload.signedUrl, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGalleryRepoSaga() {
  yield takeLatest(actions.AddGallery, AddGalleryRequest);
  yield takeLatest(actions.doUpdateGallary, updateGalleryRequest);
  yield takeLatest(actions.doGetGallaryList, getGalleryListRequest);
  yield takeLatest(actions.doGallaryDelete, doGalleryDeleteRequest);
  // yield takeLatest(actions.doAddImages, doGalleryFileUploadRequest);
  yield takeLatest(actions.doGallaryEdit, getGalleryById);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
  // yield takeLatest(actions.AddSeasonTime, AddSeasonTimeRequest);
  yield takeLatest(actions.doGallaryEditWithID, getGalleryByIdFeature);
}
