import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.seasonState;
  } else {
    return initialState;
  }
};

export const selectSeasonForm = createSelector([selectDomain], (state) => state.seasonFormData);
export const selectSeasonListData = createSelector([selectDomain], (state) => state.seasonList);
export const selectSeasonTimeSlotForm = createSelector([selectDomain], (state) => state.seasonFormData.timeSlotform);
export const selectSeasonTimeSlotDataList = createSelector([selectDomain], (state) => state.seasonFormData.timeSlot);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
