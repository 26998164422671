import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.packageState;
  } else {
    return initialState;
  }
};

export const selectPackageForm = createSelector([selectDomain], (state) => state.PackageFormData);
export const selectPackageListData = createSelector([selectDomain], (state) => state.PackageList);
export const selectPackagePhotoForm = createSelector([selectDomain], (state) => state.PackageFormData.file);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const selectSeasonPriceform = createSelector([selectDomain], (state) => state.PackageFormData.seasonPriceform);
export const selectSseasonPriceformList = createSelector([selectDomain], (state) => state.PackageFormData.seasonPrice);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectPackageAddOnsform = createSelector([selectDomain], (state) => state.PackageFormData.addOnsform);
