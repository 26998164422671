export enum StatusTypeEnum {
  ACTIVE = 1,
  INACTIVE = 2
}

export interface RoomCategoryInterface {
  id: string;
  _id: string;
  title: string;
  isActive: StatusTypeEnum;
}

export interface RoomCategoryState {
  roomCategoryList: RoomCategoryInterface[];
  roomCategoryFormData: RoomCategoryInterface;
  loading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialRoomCategoryState = RoomCategoryState;
