import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import { SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir, selectGalleryForm } from './selector';
import { TopicInterface } from '../types';
import {
  doAddNewsTopics,
  doDeleteNewsTopics,
  doGetNewsListRequest,
  doGetNewsTopicsById,
  doUpdateNewsTopics,
  getNewsImageRequest,
  uplodeImageRequest
} from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* AddGalleryRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: TopicInterface = yield select(selectGalleryForm);

  try {
    const response: AxiosResponse = yield call(doAddNewsTopics, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updateGalleryRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: TopicInterface = yield select(selectGalleryForm);

  if (form.coverImg == '') {
    yield put(
      openSnackbar({
        open: true,
        message: 'please select package photos',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doUpdateNewsTopics, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getGalleryListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      doGetNewsListRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setList(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGalleryDeleteRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Blog to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }
  try {
    const response: AxiosResponse = yield call(doDeleteNewsTopics, action.payload.id);

    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getGalleryById(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select User',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }
  try {
    const response: AxiosResponse = yield call(doGetNewsTopicsById, action.payload);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }

    yield put(actions.setForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(getNewsImageRequest, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(uplodeImageRequest, action.payload.signedUrl, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* NewsTopicsRepoSaga() {
  yield takeLatest(actions.AddGallery, AddGalleryRequest);
  yield takeLatest(actions.doUpdate, updateGalleryRequest);
  yield takeLatest(actions.doGetList, getGalleryListRequest);
  yield takeLatest(actions.doDelete, doGalleryDeleteRequest);
  yield takeLatest(actions.doEdit, getGalleryById);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
}
