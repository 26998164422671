import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { NewsCategoryState, NewsTopicsCategoryInterface } from '../types';

export const initialState: NewsCategoryState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  newsTopicsCategoryList: [],
  newsTopicsAllCategoryList: [],
  sortColumn: '',
  sortDir: '',
  newsTopicsCategoryFormData: {
    _id: '',
    title: '',
    updatedAt: '',
    createdAt: ''
  }
};

export const useClinicSlice = createSlice({
  name: 'newsCategory',
  initialState,
  reducers: {
    updateNewsCategoryFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `newsTopicsCategoryFormData.${action.payload.key}`, action.payload.value);
    },
    AddNewsCategory: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateNewsCategory: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearNewsCategoryrom: (state) => {
      state.newsTopicsCategoryFormData._id = '';
      state.newsTopicsCategoryFormData.title = '';
    },
    doPackageDelete: (state, action: PayloadAction<any>) => {},
    doNewsCategoryDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doNewsCategoryEdit: (state, action: PayloadAction<any>) => {},
    doGetNewsCategoryList: (state) => {},
    doGetNewsCategoryAllList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setNewsCategoryList: (state, action: PayloadAction<Array<NewsTopicsCategoryInterface>>) => {
      state.newsTopicsCategoryList = action.payload;
    },
    setNewsCategoryAllList: (state, action: PayloadAction<Array<NewsTopicsCategoryInterface>>) => {
      state.newsTopicsAllCategoryList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setNewsCategoryForEdit: (state, action: PayloadAction<NewsTopicsCategoryInterface>) => {
      state.newsTopicsCategoryFormData = action.payload;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
