import axios from 'axios';
import Api from './Api';

//new request code by richa
const LoginRequest = async (data: any) => {
  return Api.post(`/admin/login`, data);
};
const MeProfileRequest = async () => {
  return Api.get(`/auth/user/me`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateSchoolPasswordRequest = async (data: any) => {
  return Api.patch(`/auth/user/change-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
// package section*****
const doAddPackage = async (data: any) => {
  return Api.post(`/admin/package/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddGallery = async (data: any) => {
  return Api.post(`/gallery/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetPackageListRequest = async (filter: string) => {
  return Api.get(`/admin/package/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetGalleryListRequest = async (filter: string) => {
  return Api.get(`/gallery/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetPackageById = async (packageID: string) => {
  return Api.get(`/admin/package/get/${packageID}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetGalleryById = async (packageID: any) => {
  return Api.get(`/gallery/get/${packageID}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetBookingByIdRequestAPI = async (packageID: any) => {
  return Api.get(`/booking/get/${packageID}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeletePackage = async (packageID: string) => {
  return Api.delete(`/admin/package/delete/${packageID}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteGallery = async (packageID: string) => {
  return Api.delete(`/gallery/delete/${packageID}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doUpdatePackage = async (data: any) => {
  return Api.put(`/admin/package/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateGallery = async (data: any) => {
  return Api.put(`/gallery/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doPackageImageUpload = async (data: any) => {
  return Api.put(`/auth/user/upload`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};
const doAddPackageAvailability = async (data: any) => {
  return Api.post(`/availability/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doSearchPackageAvailability = async (filter: string) => {
  return Api.get(`/availability/package/season${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetPackageAvailabilitylist = async (filter: string) => {
  return Api.get(`/availability/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetPackageList = async () => {
  return Api.get(`/admin/package/all/list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doAddSeason = async (data: any) => {
  return Api.post(`/season/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doSeasonDelete = async (id: string) => {
  return Api.delete(`/season/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetSeasonById = async (id: string) => {
  return Api.get(`/season/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetSeasonList = async (filter: string) => {
  return Api.get(`/season/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateSeason = async (data: any) => {
  return Api.put(`/season/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetuserlist = async (filter: string) => {
  return Api.get(`/admin/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddRoomCategory = async (data: any) => {
  return Api.post(`/room/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const getImageRequest = async (data: any) => {
  return Api.post(
    `/gallery/signed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const uplodeImageRequest = async (signedUrl: any, data: any) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      'Content-Type': 'fileType'
    }
  });
};
const getBookingList = async (filter: string) => {
  return Api.get(`/booking/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const getBookingDateList = async (filter: string) => {
  return Api.get(`/booking/new/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const getCancelBookingList = async (filter: string) => {
  return Api.get(`/booking/cancelled/bookings/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const getPendingBookingList = async (filter: string) => {
  return Api.get(`/booking/pending/bookings/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const getBookingCancelById = async (userId: string) => {
  return Api.patch(
    `/booking/cancel-booking?bookingId=${userId}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doGetRoomCategoryList = async (filter: string) => {
  return Api.get(`/room/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const DeleteDateOfAvaibilityRequest = async (id: string) => {
  return Api.delete(`/availability/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetAvailabilityByIdRequest = async (id: string) => {
  return Api.get(`/availability/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdatePackageAvailability = async (data: any) => {
  return Api.put(`/availability/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteRoomCategory = async (id: string) => {
  return Api.delete(`/room/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetRoomCategoryById = async (id: string) => {
  return Api.get(`/room/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateRoomCategory = async (data: any) => {
  return Api.put(`/room/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddSiteSetting = async (data: any) => {
  return Api.post(`/setting/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetSiteSettingList = async (filter: string) => {
  return Api.get(`/setting/get${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doAddNewsCategory = async (data: any) => {
  return Api.post(`/news/category/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetNewsCategoryList = async (filter: string) => {
  return Api.get(`/news/category/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetNewsCategoryAllList = async () => {
  return Api.get(`/news/category/all/list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteNewsCategory = async (id: string) => {
  return Api.delete(`/news/category/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetNewsCategoryById = async (id: string) => {
  return Api.get(`/news/category/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateNewsCategory = async (data: any) => {
  return Api.post(`/news/category/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const getNewsImageRequest = async (data: any) => {
  return Api.post(
    `/news/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doAddNewsTopics = async (data: any) => {
  return Api.post(`/news/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetNewsListRequest = async (filter: string) => {
  return Api.get(`/news/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteNewsTopics = async (id: string) => {
  return Api.delete(`/news/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetNewsTopicsById = async (id: any) => {
  return Api.get(`/news/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateNewsTopics = async (data: any) => {
  return Api.post(`/news/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetUserById = async (user: any) => {
  return Api.get(`/admin/get/${user}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doDeleteHolidays = async (id: string) => {
  return Api.delete(`/holiday/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetNewsHolidaysList = async (filter: string) => {
  return Api.get(`/holiday/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddHolidays = async (data: any) => {
  return Api.post(`/holiday/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//************* */
const UserPictursRequest = async (data: any) => {
  return Api.put(`retreat/upload`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};

const GetUserNamelistRequest = async () => {
  return Api.get(`/user/getuserlist`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GeBlogEditRequest = async (id: string) => {
  return Api.put(`/Blog/updateBlog/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateHelpRequest = async (data: any) => {
  return Api.put(`/Blog/updateBlog/${data.id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetRoleUserEditRequest = async (data: any) => {
  console.log(data, 'GetRoleUserEditRequest');
  return Api.put(`/user/admin/update/user`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const AddProfileRequest = async (data: any) => {
  return Api.post(`/user/add/carousal/profiles`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doProfileDeleteRequest = async (id: string) => {
  return Api.delete(`/user/userprofiles/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetAdminDashboardData = async () => {
  return Api.get(`admin/get/total/user_blog`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const SaveNewMarkupRequest = async (data: any) => {
  return Api.post(`/markup/createmarkup`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetNewMarkuplistRequest = async (filter: string) => {
  return Api.get(`/markup/allmarkup${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const DeleteNewMarkupRequest = async (markupId: string) => {
  return Api.delete(`/markup/deletemarkup/${markupId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateNewMarkupRequest = async (data: any) => {
  return Api.put(`/markup/updatemarkup/${data.id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//Refund
const GetRefundlistRequest = async (filter: string) => {
  return Api.get(`/airports/getallrefundflightbookingAdmin${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetBookingslistRequest = async (filter: string) => {
  return Api.get(`/airports/getallflightbookingAdmin/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetUserlistRequest = async (filter: string) => {
  return Api.get(`/user/list/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const CancleRefundRequest = async (data: any) => {
  return Api.post(`/payments/updatePayments`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetDashboardlistRequest = async (filter: string) => {
  return Api.get(`/dashboard/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetWalletdataRequest = async () => {
  return Api.get(`/airports/getApiBalance`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetDashboardGraphDataRequest = async (data: any) => {
  return Api.get(`/dashboard/graph/${data}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetDashboardSearchRequest = async () => {
  return Api.get(`/dashboard/search/month`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetTicketStatusRequest = async (referenceNo: any) => {
  return Api.get(`/airports/flightticketstatus/${referenceNo}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveuserRequest = async (data: any) => {
  return Api.post(`/user/add/user`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GeUploadeRequest = async (data: any) => {
  return Api.patch(`/user/file/add`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};
const GeBlogUploadeRequest = async (data: any) => {
  return Api.patch(`/user/file/add`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};
const DeleteuserRequest = async (userId: string) => {
  return Api.delete(`/user/delete/${userId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetUserByIdRequest = async (userId: string) => {
  return Api.get(`/user/getById/${userId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const SaveCouponRequest = async (data: any) => {
  return Api.post(`/coupon/createCoupon`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetCouponlistRequest = async (filter: string) => {
  return Api.get(`/coupon/allCoupon${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GeUploadeCouponRequest = async (data: any) => {
  return Api.put(`/coupon/upload/`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};
const DeleteCouponRequest = async (CouponId: any) => {
  return Api.delete(`/coupon/deleteCoupon/${CouponId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateCouponRequest = async (data: any) => {
  console.log(data, 'UpdateCouponRequest');
  return Api.put(`coupon/updatecoupon/${data.id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
export {
  getBookingDateList,
  doAddGallery,
  LoginRequest,
  MeProfileRequest,
  doAddPackage,
  doGetPackageListRequest,
  doDeletePackage,
  doGetPackageById,
  doPackageImageUpload,
  doAddPackageAvailability,
  doGetPackageAvailabilitylist,
  doAddSeason,
  doGetuserlist,
  doGetSeasonList,
  doAddRoomCategory,
  getImageRequest,
  uplodeImageRequest,
  getBookingList,
  getBookingCancelById,
  doGetRoomCategoryList,
  doUpdatePackage,
  doSeasonDelete,
  doGetSeasonById,
  doUpdateSeason,
  doUpdatePackageAvailability,
  doDeleteRoomCategory,
  doGetRoomCategoryById,
  doUpdateRoomCategory,
  doAddSiteSetting,
  doGetSiteSettingList,
  getCancelBookingList,
  doAddNewsCategory,
  doGetNewsCategoryList,
  doGetNewsCategoryAllList,
  doDeleteNewsCategory,
  doGetNewsCategoryById,
  doUpdateNewsCategory,
  getNewsImageRequest,
  doAddNewsTopics,
  doGetNewsListRequest,
  doDeleteNewsTopics,
  doGetNewsTopicsById,
  doUpdateNewsTopics,
  doGetUserById,
  doGetPackageList,
  doDeleteHolidays,
  doGetNewsHolidaysList,
  doAddHolidays,
  getPendingBookingList,
  // ******
  DeleteDateOfAvaibilityRequest,
  GetUserNamelistRequest,
  GetUserByIdRequest,
  GeBlogUploadeRequest,
  GeBlogEditRequest,
  UpdateHelpRequest,
  UpdateCouponRequest,
  DeleteNewMarkupRequest,
  SaveNewMarkupRequest,
  GetNewMarkuplistRequest,
  GetRefundlistRequest,
  GetUserlistRequest,
  GetBookingslistRequest,
  CancleRefundRequest,
  GetRoleUserEditRequest,
  GetDashboardlistRequest,
  GetDashboardGraphDataRequest,
  GetWalletdataRequest,
  GetTicketStatusRequest,
  GetDashboardSearchRequest,
  SaveuserRequest,
  GeUploadeRequest,
  DeleteuserRequest,
  SaveCouponRequest,
  GetCouponlistRequest,
  GeUploadeCouponRequest,
  DeleteCouponRequest,
  UpdateNewMarkupRequest,
  UserPictursRequest,
  AddProfileRequest,
  doProfileDeleteRequest,
  GetAvailabilityByIdRequest,
  GetAdminDashboardData,
  UpdateSchoolPasswordRequest,
  doGetGalleryListRequest,
  doDeleteGallery,
  doUpdateGallery,
  doGetGalleryById,
  doGetBookingByIdRequestAPI,
  doSearchPackageAvailability
};
