import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state && state.bookingState) {
    if (state.bookingState) {
      return state.bookingState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectBookingFormData = createSelector([selectDomain], (state) => state.bookingFormData);
export const selectBookingListData = createSelector([selectDomain], (state) => state.bookingList);
export const selectBookingCancelListData = createSelector([selectDomain], (state) => state.bookingCancelList);
export const selectBookingCancelFormData = createSelector([selectDomain], (state) => state.bookingCancelFormData);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectPageNoCancel = createSelector([selectDomain], (state) => state.pageNoCancel);
export const SelectPageSizeCancel = createSelector([selectDomain], (state) => state.pageSizeCancel);
export const SelectTotalRowCancel = createSelector([selectDomain], (state) => state.totalRowCancel);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectCancelSortColumn = createSelector([selectDomain], (state) => state.sortColumnCancel);
export const SelectCancelSortDir = createSelector([selectDomain], (state) => state.sortDirCancel);
//
export const SelectPendingSortColumn = createSelector([selectDomain], (state) => state.sortColumnPending);
export const SelectPendingSortDir = createSelector([selectDomain], (state) => state.sortDirPending);
export const SelectPageNoPending = createSelector([selectDomain], (state) => state.pageNoPending);
export const SelectPageSizePending = createSelector([selectDomain], (state) => state.pageSizePending);
export const SelectTotalRowPending = createSelector([selectDomain], (state) => state.totalRowPending);
export const selectBookingPendingListData = createSelector([selectDomain], (state) => state.bookingPendingList);
export const selectBookingPendingFormData = createSelector([selectDomain], (state) => state.bookingPendingFormData);
//
export const SelectSortColumnDateBooking = createSelector([selectDomain], (state) => state.sortColumnDateBooking);
export const SelectSortDirDateBooking = createSelector([selectDomain], (state) => state.sortDirDateBooking);
export const SelectPageNoDateBooking = createSelector([selectDomain], (state) => state.pageNoDateBooking);
export const SelectPageSizeDateBooking = createSelector([selectDomain], (state) => state.pageSizeDateBooking);
export const SelectTotalRowDateBooking = createSelector([selectDomain], (state) => state.totalRowDateBooking);
export const selectBookingDateList = createSelector([selectDomain], (state) => state.bookingDateList);
export const selectBookingDateFormData = createSelector([selectDomain], (state) => state.bookingDateFormData);
