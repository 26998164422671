import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import {  SelectendDate, SelectstartDate } from './selector';
import {
  GetDashboardlistRequest,
  GetDashboardGraphDataRequest,
  GetWalletdataRequest,
  GetDashboardSearchRequest,
  GetAdminDashboardData
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
export function* getDashboardListRequest() {
  yield delay(500);

  try {
    const startDate: string = yield select(SelectstartDate);
    const endDate: string = yield select(SelectendDate);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetDashboardlistRequest,
      `?startDate=${startDate}&endDate=${endDate}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setDashboardsList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
      openSnackbar({
        open: true,
        message: error.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });
    }
  }
}
export function* getWalletInfoRequest() {
  yield delay(500);

  try {
    // const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    // const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetWalletdataRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    // console.log(response.data.data.Balance , "wallet")
    yield put(actions.setWalletData(response.data.data.Balance));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
      openSnackbar({
        open: true,
        message: error.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });
    }
  }
}
export function* getDashboardGraphDataRequest(data: any) {
  console.log(data.payload, 'getDashboardGraphDataRequest');
  yield delay(500);
  try {
    // const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    // const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetDashboardGraphDataRequest, data.payload);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));

    yield put(actions.setGraphData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
      openSnackbar({
        open: true,
        message: error.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });
    }
  }
}
export function* getSearchDataRequest() {
  yield delay(500);
  try {
    // const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    // const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetDashboardSearchRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    {
      console.log(response.data.data, 'SagaSeaerch');
    }
    yield put(actions.setSearchData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
      openSnackbar({
        open: true,
        message: error.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });
    }
  }
}
export function* getAdminDashboardDataListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetAdminDashboardData);

    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });
      return;
    }
    yield put(actions.setAdminDashboardDataList(response.data.data));
  } catch (error: any) {
    if (error.message !== 'call: argument fn is undefined or null') {
      if (error.response.status !== 401) {
        if (error.response) {
          if (error.response.data.valData) {
            let dataerrer = error.response.data.valData.errors.map((item: any) => {
              return { name: item.param, message: item.msg };
            });
            for (let index = 0; index < dataerrer.length; index++) {
              const element = dataerrer[index];

              openSnackbar({
                open: true,
                message: element.message,
                variant: 'alert',
                alert: {
                  color: 'success'
                },
                close: false
              });
            }
          } else {
            openSnackbar({
              open: true,
              message: error.response.data.message,
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            });
          }
        } else {
          openSnackbar({
            open: true,
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          });
        }
      } else {
        localStorage.removeItem('token');
      }
    }
  }
}
export function* authRepoSagaDashboard() {
  yield takeLatest(actions.doGetDashboardList, getDashboardListRequest);
  yield takeLatest(actions.setSearch, getDashboardListRequest);
  yield takeLatest(actions.doGetDashboardGraphData, getDashboardGraphDataRequest);
  yield takeLatest(actions.doGetWallet, getWalletInfoRequest);
  yield takeLatest(actions.doGetWallet, getSearchDataRequest);
  yield takeLatest(actions.doGetDashboardTotalList, getAdminDashboardDataListRequest);
}
