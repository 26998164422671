import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { AddRoleState, RoleUserInterface } from '../types';

export const initialState: AddRoleState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  RoleUserList: [],
  RoleUserFormData: {
    email: '',
    fullName: '',
    googleid: '',
    id: '',
    phoneno: '',
    profilepic: 'dbdbdf',
    username: '',
    confirmPassword: '',
    newPassword: '',
    status: 1,
    usertype: 1
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  updatePasswordList: [],
  updatePasswordFormData: { id: '', password: '', newPassword: '' }
};

export const useClinicSlice = createSlice({
  name: 'AddRoletate',
  initialState,
  reducers: {
    updateNewRoleUserFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      //@ts-ignore
      state.RoleUserFormData[action.payload.key] = action.payload.value;
    },
    userUpdatePasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `updatePasswordFormData.${action.payload.key}`, action.payload.value);
    },
    doUpdateUserPassword: (state, action: PayloadAction<{ callback: () => void }>) => {},
    NewRoleUserAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
      // state.FaqList.push(state.FaqFormData);
    },
    clearNewRoleUserFrom: (state) => {
      state.RoleUserFormData.email = '';
      state.RoleUserFormData.fullName = '';
      state.RoleUserFormData.googleid = '';
      state.RoleUserFormData.id = '';
      // state.RoleUserFormData.password= "";
      state.RoleUserFormData.phoneno = '';
      state.RoleUserFormData.profilepic = '';
      state.RoleUserFormData.username = '';
    },
    clearPasswordForm: (state) => {
      state.updatePasswordFormData.id = '';
      state.updatePasswordFormData.password = '';
      state.updatePasswordFormData.newPassword = '';
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Loading');
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      console.log('pageNo');
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      console.log('pageNo');
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      console.log('pageSize');
      state.pageSize = action.payload;
    },
    doGetRoleUserList: (state) => {},
    setDayList: (state, action: PayloadAction<Array<RoleUserInterface>>) => {
      state.RoleUserList = action.payload;
    },
    userDelete: (state, action: PayloadAction<any>) => {},
    userEdit: (state, action: PayloadAction<any>) => {},
    setUserForEdit: (state, action: PayloadAction<RoleUserInterface>) => {
      state.RoleUserFormData = action.payload;
    },
    updateDataRoleUser: (state, action: PayloadAction<{ callback: () => void }>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
