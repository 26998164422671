import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state && state.newsCategory) {
    return state.newsCategory;
  } else {
    return initialState;
  }
};

export const selectNewsCategoryForm = createSelector([selectDomain], (state) => state.newsTopicsCategoryFormData);
export const selectNewsCategoryList = createSelector([selectDomain], (state) => state.newsTopicsCategoryList);
export const selectNewsCategoryAllList = createSelector([selectDomain], (state) => state.newsTopicsAllCategoryList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
