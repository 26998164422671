import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
import { SeasonAddInterface } from '../types';
import { SelectPageNo, SelectPageSize, SelectSearch, selectSeasonForm, SelectSortColumn, SelectSortDir } from './selector';
import { doAddSeason, doGetSeasonList, doGetSeasonById, doSeasonDelete, doUpdateSeason } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* AddSeasonRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: SeasonAddInterface = yield select(selectSeasonForm);
  if (form.title.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doAddSeason, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getSeasonListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetSeasonList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }
    yield put(actions.setTotalRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setSeasonist(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doSeasonDeleteRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Blog to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doSeasonDelete, action.payload.id);

    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getSeasonByIdRequest(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select User',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doGetSeasonById, action.payload);

    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }

    yield put(actions.setSeasonForEdit(response.data.data[0]));
    yield put(actions.setSeasonTimeForEdit(response.data.data[0].timeSlot));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateSeasonRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: SeasonAddInterface = yield select(selectSeasonForm);
  if (form.title.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doUpdateSeason, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doSessionRepoSaga() {
  yield takeLatest(actions.AddSeason, AddSeasonRequest);
  yield takeLatest(actions.doGetSeasonList, getSeasonListRequest);
  yield takeLatest(actions.doSeasonDelete, doSeasonDeleteRequest);
  yield takeLatest(actions.doSeasonEdit, getSeasonByIdRequest);
  yield takeLatest(actions.doUpdateSeason, doUpdateSeasonRequest);
}
