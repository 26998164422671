import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
import { PackageInterface, TIME_SLOT_TYPE_ENUM, packagePhotos } from '../types';
import {
  selectPackageForm,
  selectPackagePhotoForm,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectSortColumn,
  SelectSortDir
} from './selector';
import {
  doGetPackageById,
  doGetPackageListRequest,
  doAddPackage,
  doDeletePackage,
  doPackageImageUpload,
  getImageRequest,
  uplodeImageRequest,
  doUpdatePackage
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* AddPackageRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: PackageInterface = yield select(selectPackageForm);
  if (form.title.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.helpText.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter help text',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.maxPeople == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter max people',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.numberOfPeople == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter no of people',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.price == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.weekendPrice == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter week end price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.peopleExtraPrice == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter people extra price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.childrenExtraPrice == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter child extra price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.orderBy == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter order',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.timeSlot == TIME_SLOT_TYPE_ENUM.MULTIPLE) {
    if (form.inventory == 0) {
      yield put(
        openSnackbar({
          open: true,
          message: 'please enter inventory',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
  }

  if (form.roomCategory.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter room category',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.file.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please select package photos',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  // if (form.addOns.length == 0) {
  //   yield put(
  //     openSnackbar({
  //       open: true,
  //       message: 'please add on',
  //       variant: 'alert',
  //       alert: {
  //         color: 'error'
  //       },
  //       close: true
  //     })
  //   );
  //   return;
  // }
  // if (form.seasonPrice.length == 0) {
  //   yield put(
  //     openSnackbar({
  //       open: true,
  //       message: 'please add season',
  //       variant: 'alert',
  //       alert: {
  //         color: 'error'
  //       },
  //       close: true
  //     })
  //   );
  //   return;
  // }

  try {
    const response: AxiosResponse = yield call(doAddPackage, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updatePackageRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: PackageInterface = yield select(selectPackageForm);
  if (form.title.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.helpText.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter help text',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.maxPeople == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter people',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doUpdatePackage, form);
    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getPackageListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      doGetPackageListRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setPackageist(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doPackageDeleteRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Blog to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doDeletePackage, action.payload.id);

    if (response && !response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getPackageById(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select User',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doGetPackageById, action.payload);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }

    yield put(actions.setPackageForEdit(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doPackageFileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);
  const PhotoForm: packagePhotos[] = yield select(selectPackagePhotoForm);
  try {
    // const [uploadPromise, chan] = createUploader(action.payload.data);
    // yield fork(watchOnProgress, chan);
    // const response: AxiosResponse = yield call(() => uploadPromise);
    const response: AxiosResponse = yield call(doPackageImageUpload, action.payload.data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    var arrayImageStatus = PhotoForm.filter((val: packagePhotos) => response.data.data.fileName == val.fileName).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.setPhotoData({
          photo: response.data.data.name,
          fileName: response.data.data.fileName
        })
      );
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: 'Image Already Uploaded',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getImageRequest, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(uplodeImageRequest, action.payload.signedUrl, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddSeasonTimeRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {}
}
export function* AddOnSectionRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {}
}
export function* doPackageRepoSaga() {
  yield takeLatest(actions.AddPackage, AddPackageRequest);
  yield takeLatest(actions.doUpdatePackage, updatePackageRequest);
  yield takeLatest(actions.doGetPackageList, getPackageListRequest);
  yield takeLatest(actions.doPackageDelete, doPackageDeleteRequest);
  yield takeLatest(actions.doAddImages, doPackageFileUploadRequest);
  yield takeLatest(actions.doPackageEdit, getPackageById);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
  yield takeLatest(actions.AddSeasonTime, AddSeasonTimeRequest);
  yield takeLatest(actions.AddOnSection, AddOnSectionRequest);
}
