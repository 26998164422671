import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { HasDog, UserStatusEnum, userInterface, userState } from '../types';

export const initialState: userState = {
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  userList: [],
  cropShow: false,
  loading: false,
  sortColumn: '',
  sortDir: '',
  userDetails: {
    _id: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    englishFirstName: '',
    englishLastName: '',
    pincode: '',
    state: '',
    district: '',
    city: '',
    apartment: '',
    hasDog: HasDog.NO,
    dogDetail: { breedName: '', dateOfVaccine: '', dogName: '', file: '' },
    dogDetailList: [],
    mobileNumber: '',
    status: UserStatusEnum.INACTIVE
  }
};

export const useClinicSliceTodayActivity = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    doGetuserList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    updateNewuserFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      //@ts-ignore
      state.userFormData[action.payload.key] = action.payload.value;
    },
    NewuserAdded: (state, action: PayloadAction<{ callback: () => void }>) => {},

    userDelete: (state, action: PayloadAction<any>) => {},
    doUserById: (state, action: PayloadAction<any>) => {},
    userEdit: (state, action: PayloadAction<any>) => {},

    Uploadeuser: (state, action: PayloadAction<any>) => {},
    setTotalRow: (state, action: PayloadAction<number>) => {
      console.log('pageNo');
      state.totalRow = action.payload;
    },
    setUserList: (state, action: PayloadAction<Array<userInterface>>) => {
      state.userList = action.payload;
    },

    updateDataHelp: (state, action: PayloadAction<{ key: string; data: any; callback: any }>) => {},
    doAddImages: (state, action: PayloadAction<{ data: any; type: number; callback: any }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    toggleCropShow: (state) => {
      state.cropShow = !state.cropShow;
    },
    setUserDetails: (state, action: PayloadAction<userInterface>) => {
      state.userDetails = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSliceTodayActivity;
