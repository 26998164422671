// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  ProjectOutlined,
  ApartmentOutlined,
  UngroupOutlined,
  AlipayCircleOutlined,
  AccountBookOutlined,
  SettingOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  ProjectOutlined,
  ApartmentOutlined,
  UngroupOutlined,
  AlipayCircleOutlined,
  AccountBookOutlined,
  SettingOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'offer',
      title: <FormattedMessage id="Package" />,
      type: 'collapse',
      icon: icons.ApartmentOutlined,
      children: [
        {
          id: 'product-list',
          title: <FormattedMessage id="Package List" />,
          type: 'item',
          url: '/offer/product-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'season',
      title: <FormattedMessage id="Season Table" />,
      type: 'collapse',
      icon: icons.UngroupOutlined,
      children: [
        {
          id: 'season-list',
          title: <FormattedMessage id="Season List" />,
          type: 'item',
          url: '/season/season-list',
          breadcrumbs: false
        }
      ]
    },

    {
      id: 'availability',
      title: <FormattedMessage id="Package Availability" />,
      type: 'collapse',
      icon: icons.AlipayCircleOutlined,
      children: [
        {
          id: 'availability-list',
          title: <FormattedMessage id="Availability list" />,
          type: 'item',
          url: '/availability/availability-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'holidays',
      title: <FormattedMessage id="Holiday" />,
      type: 'collapse',
      icon: icons.AlipayCircleOutlined,
      children: [
        {
          id: 'holidays-list',
          title: <FormattedMessage id="Holiday list" />,
          type: 'item',
          url: '/holidays/holidays-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'user',
      title: <FormattedMessage id="User" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'offer-list',
          title: <FormattedMessage id="User-list" />,
          type: 'item',
          url: '/user/product-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'roomcategory',
      title: <FormattedMessage id="Dog Category" />,
      type: 'collapse',
      icon: icons.ProjectOutlined,
      children: [
        {
          id: 'offer-list',
          title: <FormattedMessage id="Dog Category List" />,
          type: 'item',
          url: '/roomcategory/room-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'booking',
      title: <FormattedMessage id="Booking" />,
      type: 'collapse',
      icon: icons.AccountBookOutlined,
      children: [
        {
          id: 'booking-list',
          title: <FormattedMessage id="Booking List" />,
          type: 'item',
          url: '/profile/profile-list',
          breadcrumbs: false
        },
        {
          id: 'booking-date-list',
          title: <FormattedMessage id="Booking Date List" />,
          type: 'item',
          url: '/profile/bookingdatelist',
          breadcrumbs: false
        },

        {
          id: 'pendinglist',
          title: <FormattedMessage id="Pending Booking List" />,
          type: 'item',
          url: '/profile/pendinglist',
          breadcrumbs: false
        },
        {
          id: 'cancel-list',
          title: <FormattedMessage id="Cancel Booking List" />,
          type: 'item',
          url: '/profile/cancel-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'gallery',
      title: <FormattedMessage id="Gallery" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'offer-list',
          title: <FormattedMessage id="Gallery List" />,
          type: 'item',
          url: '/gallery/product-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'news-topics',
      title: <FormattedMessage id="News Topics" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'offer-list',
          title: <FormattedMessage id="News Topics List" />,
          type: 'item',
          url: '/news-topics/news-topics-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'categorytopics',
      title: <FormattedMessage id="News Category" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'offer-list',
          title: <FormattedMessage id="News Category List" />,
          type: 'item',
          url: '/categorytopics/categorytopics-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'setting',
      title: <FormattedMessage id="Setting" />,
      type: 'collapse',
      icon: icons.SettingOutlined,
      children: [
        {
          id: 'setting',
          title: <FormattedMessage id="Setting" />,
          type: 'item',
          url: '/setting/add-setting',
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default custom;
