import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
import { CouponInterface } from '../types';
import { selectCouponForm, SelectPageNo, SelectSearch } from './selector';
import { DeleteCouponRequest, GetCouponlistRequest, GeUploadeRequest, SaveCouponRequest, UpdateCouponRequest } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';

// import { openSnackbar } from 'store/reducers/snackbar';

export function* CouponAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  let form: CouponInterface = yield select(selectCouponForm);

  try {
    const response: AxiosResponse = yield call(SaveCouponRequest, form);
    if (response && !response.data) {
      // toast.error(response.data.message);

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];
             openSnackbar({
          open: true,
          message: element.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
        }
      } else {
           openSnackbar({
          open: true,
          message: error.response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      }
    } else {
         openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
    }
  }
}

export function* getCouponListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetCouponlistRequest,
      `?pageNo=${pageNo}&pageSize=10000${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    {
      console.log(response.data.data.results, 'coupondata');
    }
    yield put(actions.setDayList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
      
    
        openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
     
    }
  }
}
export function* FileUploadRequest(action: { payload: { data: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GeUploadeRequest, action.payload.data);
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(
      actions.updateNewCouponFormValue({
        key: 'image',
        value: response.data.data.name
      })
    );
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
       openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
    }
  }
}
export function* deleteCoupon(action: { payload: string }) {
  yield delay(500);

console.log(action.payload , "deleteCoupon")
  try {
    const response: AxiosResponse = yield call(DeleteCouponRequest, action.payload);
  
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield put(actions.doGetCouponList());
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];

          yield put(
            openSnackbar({
              open: true,
              message: element.message,
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          );
        }
      } else {
        yield put(
          openSnackbar({
            open: true,
            message: error.response.data.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }
  }
}
export function* CouponEditRequest(action: { payload: any }) {
  yield put(actions.setCouponForEdit(action.payload));
}
export function* updateCouponRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: CouponInterface = yield select(selectCouponForm);

  try {
    const response: AxiosResponse = yield call(UpdateCouponRequest, form);
    if (response && !response.data) {
      return;
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];
             openSnackbar({
          open: true,
          message: element.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
       openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
    }
  }

  // yield put(actions.updateSuccessHelpMe());
  // yield call(action?.payload?.callback);
}
export function* authRepoSagaCoupon() {
  yield takeLatest(actions.NewCouponAdded, CouponAddRequest);
  yield takeLatest(actions.doGetCouponList, getCouponListRequest);
  yield takeLatest(actions.setSearch, getCouponListRequest);
  yield takeLatest(actions.doAddImages, FileUploadRequest);
  yield takeLatest(actions.CouponDelete, deleteCoupon);
  yield takeLatest(actions.CouponEdit, CouponEditRequest);
  yield takeLatest(actions.updateDataHelp, updateCouponRequest);
}

// questionAddRequestMain
