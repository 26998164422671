import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import moment from 'moment';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { PackageAvailabilityInterface, PackageAvailabilityState, packageListInterface } from '../types';
const today: any = moment;
export const initialState: PackageAvailabilityState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  packageAvailabilityList: [],
  packageAvailabilityFormData: {
    id: '',
    _id: '',
    packageId: '',
    // price: '',
    date: today,
    startDate: "",
    endDate: new Date(today),
    inventory: 0,
    packageTitle: '',
    multiTimeSlot: []
    // isActive: StatusTypeEnum.ACTIVE
  },
  sortColumn: '',
  sortDir: '',
  packageList: [],
  packageMultiAvailability: []
};

export const useClinicSlice = createSlice({
  name: 'packageAvailability',
  initialState,
  reducers: {
    updatePackageAvailabilityFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `packageAvailabilityFormData.${action.payload.key}`, action.payload.value);
    },
    doAddPackageAvailability: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doAskMultiSummer: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdatePackageAvailabilitySlice: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearNewdateSelectFrom: (state) => {
      state.packageAvailabilityFormData.id = '';
      state.packageAvailabilityFormData._id = '';
      state.packageAvailabilityFormData.packageId = '';
      state.packageAvailabilityFormData.inventory = '';
      state.packageAvailabilityFormData.date = new Date(today);
      state.packageAvailabilityFormData.startDate = moment().toISOString();
      state.packageAvailabilityFormData.endDate = new Date(today);
      state.packageAvailabilityFormData.multiTimeSlot = [];
    },
    doGetPackageAvailabilityList: (state) => {},

    dateSelectDelete: (state, action: PayloadAction<any>) => {},
    doPackageAvailabilityDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    setPackageAvailabilityList: (state, action: PayloadAction<Array<PackageAvailabilityInterface>>) => {
      state.packageAvailabilityList = action.payload;
    },
    setPackageAvailabilityForEdit: (state, action: PayloadAction<PackageAvailabilityInterface>) => {
      state.packageAvailabilityFormData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    doAvaiabilityById: (state, action: PayloadAction<any>) => {},

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPackageList: (state, action: PayloadAction<Array<packageListInterface>>) => {
      state.packageList = action.payload;
    },
    doGetPackageList: (state) => {},
    setMultiPackageAvailabilityList: (state, action: PayloadAction<any>) => {
      state.packageMultiAvailability = action.payload;
    },
    // selectDateForPackageBooking: (
    //   state,
    //   action: PayloadAction<{
    //     index: number;
    //     date: string;
    //     endTime: string;
    //     startTime: string;
    //     timeSlot: number;
    //     timeSlotIndex: number;
    //   }>
    // ) => {
    //   const isIndexExists = state.packageAvailabilityFormData.multiTimeSlot.some((obj) => obj.index === action.payload.index);
    //   if (isIndexExists == false) {
    //     state.packageAvailabilityFormData.multiTimeSlot.push({
    //       timeSlotIndex: action.payload.timeSlotIndex,
    //       date: action.payload.date,
    //       startTime: action.payload.startTime,
    //       endTime: action.payload.endTime,
    //       timeSlot: action.payload.timeSlot,
    //       index: action.payload.index
    //     });
    //   } else {
    //     const indexOfObject = state.packageAvailabilityFormData.multiTimeSlot.findIndex((obj) => obj.index === action.payload.index);
    //     state.packageAvailabilityFormData.multiTimeSlot.splice(indexOfObject, 1);
    //     state.packageAvailabilityFormData.multiTimeSlot.push({
    //       timeSlotIndex: action.payload.timeSlotIndex,
    //       date: action.payload.date,
    //       startTime: action.payload.startTime,
    //       endTime: action.payload.endTime,
    //       timeSlot: action.payload.timeSlot,
    //       index: action.payload.index
    //     });
    //   }
    // }
    selectDateForPackageBooking: (
      state,
      action: PayloadAction<{
        index: number;
       
        date: string;
        
        timeSlot: number;
        startTime: string;
        endTime: string;
        timeSlotIndex: number;
      }>
    ) => {
      const ind = state.packageAvailabilityFormData.multiTimeSlot.findIndex(
        (x) =>
          
          x.date == action.payload.date &&
         
          x.timeSlot == action.payload.timeSlot &&
          x.startTime == action.payload.startTime &&
          x.endTime == action.payload.endTime &&
          x.timeSlotIndex == action.payload.timeSlotIndex
      );

      if (ind !== -1) {
        state.packageAvailabilityFormData.multiTimeSlot.splice(ind, 1);
      } else {
        state.packageAvailabilityFormData.multiTimeSlot.push({
          timeSlotIndex: action.payload.timeSlotIndex,
          date: action.payload.date,
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          timeSlot: action.payload.timeSlot,
          index: action.payload.index
        });
      }
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
