import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { sliceKey as MenusliceKey, reducer as menuReducer } from 'store/reducers/menu';
import { sliceKey as FeqsliceKey, reducer as FaqReducer } from 'pages/offer/redux/slice';
import { authRepoSaga } from 'sections/auth/redux/saga';
import { sliceKey as AuthsliceKey, reducer as AuthReducer } from 'sections/auth/redux/slice';
import { doPackageRepoSaga } from 'pages/offer/redux/saga';
import { sliceKey as packageSliceKey, reducer as PackageReducer } from 'pages/offer/redux/slice';
import { sliceKey as RoleUsersliceKey, reducer as RoleUserReducer } from 'sections/apps/profiles/account/redux/slice';
import { authRepoSagaRoleUser } from 'sections/apps/profiles/account/redux/saga';
import { sliceKey as DashboardSliceKey, reducer as DashboardReducer } from 'pages/dashboard/redux/slice';
import { authRepoSagaDashboard } from 'pages/dashboard/redux/saga';
import { authRepoSagauser } from 'pages/user/redux/saga';
import { sliceKey as userliceKey, reducer as OfferReducer } from 'pages/user/redux/slice';
import { authRepoSagaCoupon } from 'pages/coupon/redux/saga';
import { sliceKey as CouponSliceKey, reducer as CouponReducer } from 'pages/coupon/redux/slice';
import { authRepoSagadateSelect } from 'pages/dateselect/redux/saga';
import { sliceKey as dateSelectSliceKey, reducer as dateSelectReducer } from 'pages/dateselect/redux/slice';
import { sliceKey as profileSliceKey, reducer as profileReducer } from 'pages/all-profile/redux/slice';
import { profileRepoSaga } from 'pages/all-profile/redux/saga';
//***session section */
import { sliceKey as sessionSliceKey, reducer as sessionReducer } from 'pages/session-table/redux/slice';
import { doSessionRepoSaga } from 'pages/session-table/redux/saga';
//** room category */
import { sliceKey as RoomCategorySliceKey, reducer as RoomCategoryReducer } from 'pages/room-category/redux/slice';
import { RoomCategoryRepoSaga } from 'pages/room-category/redux/saga';
//** Site Setting */
import { sliceKey as SiteSettingSliceKey, reducer as SiteSettingReducer } from 'pages/setting/redux/slice';
import { SiteSettingRepoSaga } from 'pages/setting/redux/saga';
import { doGalleryRepoSaga } from 'pages/gallery/redux/saga';
import { sliceKey as GallerySliceKey, reducer as GalleryReducer } from 'pages/gallery/redux/slice';
import { sliceKey as NewsTopicsSliceKey, reducer as NewsTopicsReducer } from 'pages/news-section/redux/slice';
import { sliceKey as NewsTopicsCategorySliceKey, reducer as NewsTopicsCategoryReducer } from 'pages/newscategory/redux/slice';
import { NewsTopicsRepoSaga } from 'pages/news-section/redux/saga';
import { NewsTopicsCategoryRepoSaga } from 'pages/newscategory/redux/saga';

//**** Holidays ****//
import { sliceKey as HolidaysSliceKey, reducer as HolidaysReducer } from 'pages/holidays/redux/slice';
import { HolidaysRepoSaga } from 'pages/holidays/redux/saga';

const App = () => {
  useInjectReducer({ key: MenusliceKey, reducer: menuReducer });
  useInjectReducer({ key: FeqsliceKey, reducer: FaqReducer });
  //auth
  useInjectReducer({ key: AuthsliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthsliceKey, saga: authRepoSaga });
  //Package
  useInjectReducer({ key: GallerySliceKey, reducer: GalleryReducer });
  useInjectSaga({ key: GallerySliceKey, saga: doGalleryRepoSaga });

  useInjectReducer({ key: packageSliceKey, reducer: PackageReducer });
  useInjectSaga({ key: packageSliceKey, saga: doPackageRepoSaga });

  //Session
  useInjectReducer({ key: sessionSliceKey, reducer: sessionReducer });
  useInjectSaga({ key: sessionSliceKey, saga: doSessionRepoSaga });
  //Room Category
  useInjectReducer({ key: RoomCategorySliceKey, reducer: RoomCategoryReducer });
  useInjectSaga({ key: RoomCategorySliceKey, saga: RoomCategoryRepoSaga });
  //Site Setting
  useInjectReducer({ key: SiteSettingSliceKey, reducer: SiteSettingReducer });
  useInjectSaga({ key: SiteSettingSliceKey, saga: SiteSettingRepoSaga });
  //RoleUser
  useInjectReducer({ key: RoleUsersliceKey, reducer: RoleUserReducer });
  useInjectSaga({ key: RoleUsersliceKey, saga: authRepoSagaRoleUser }); //dashboard

  useInjectReducer({ key: DashboardSliceKey, reducer: DashboardReducer });
  useInjectSaga({ key: DashboardSliceKey, saga: authRepoSagaDashboard });
  useInjectReducer({ key: userliceKey, reducer: OfferReducer });
  useInjectSaga({ key: userliceKey, saga: authRepoSagauser });

  useInjectReducer({ key: CouponSliceKey, reducer: CouponReducer });
  useInjectSaga({ key: CouponSliceKey, saga: authRepoSagaCoupon });

  useInjectReducer({ key: dateSelectSliceKey, reducer: dateSelectReducer });
  useInjectSaga({ key: dateSelectSliceKey, saga: authRepoSagadateSelect });

  useInjectReducer({
    key: profileSliceKey,
    reducer: profileReducer
  });
  useInjectSaga({ key: profileSliceKey, saga: profileRepoSaga });
  //****News Category Topics ****//

  useInjectSaga({ key: NewsTopicsSliceKey, saga: NewsTopicsRepoSaga });
  useInjectReducer({
    key: NewsTopicsSliceKey,
    reducer: NewsTopicsReducer
  });
  useInjectReducer({
    key: NewsTopicsCategorySliceKey,
    reducer: NewsTopicsCategoryReducer
  });
  useInjectSaga({ key: NewsTopicsCategorySliceKey, saga: NewsTopicsCategoryRepoSaga });
  //**** Holidays ****//
  useInjectReducer({
    key: HolidaysSliceKey,
    reducer: HolidaysReducer
  });
  useInjectSaga({ key: HolidaysSliceKey, saga: HolidaysRepoSaga });
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
