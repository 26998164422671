// project import
// import applications from './applications';
import dashboard from './dashboard';
// import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import other from './other';
// import pages from './pages';
import { NavItemType } from 'types/menu';

import custom from './custom';
import refundteam from './refundteam';

// ==============================|| MENU ITEMS ||============================== //

export const menuItems: { items: NavItemType[] } = {
  // items: [dashboard, custom, widget, applications, formsTables, chartsMap, pages, other]
  items: [dashboard, custom]
};

const menuItemsSecond: { items: NavItemType[] } = {
  // items: [dashboard, custom ,  widget, applications, formsTables, chartsMap, pages, other]
  items: [refundteam]
};

export default menuItemsSecond;
