import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { CouponInterface, CouponState } from '../types';

export const initialState: CouponState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  CouponList: [],
  CouponFormData: {
    description: '',
    id: '',
   couponDiscount: '0',
    couponDiscountPercentage: '-',
    couponDiscountType: 0,
    status: 0,
    title: ''
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false
};

export const useClinicSliceTodayActivity = createSlice({
  name: 'CouponState',
  initialState,
  reducers: {
    updateNewCouponFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      //@ts-ignore
      state.CouponFormData[action.payload.key] = action.payload.value;
    },
    NewCouponAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
      // state.CouponList.push(state.CouponFormData);
    },
    clearNewCouponFrom: (state) => {
      state.CouponFormData.id = '';
  
      state.CouponFormData.description = '';
      state.CouponFormData.title = '';
      state.CouponFormData.couponDiscount = '';
      state.CouponFormData.couponDiscountPercentage = '';
    },
    CouponDelete: (state, action: PayloadAction<any>) => {
      // state.CouponList.splice(action.payload , 1)
    },
    CouponEdit: (state, action: PayloadAction<any>) => {},
    doGetCouponList: (state) => {},
    UploadeCoupon: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Loading');
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      console.log('pageNo');
      state.totalRow = action.payload;
    },
    setDayList: (state, action: PayloadAction<Array<CouponInterface>>) => {
      state.CouponList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      console.log('pageNo');
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      console.log('pageSize');
      state.pageSize = action.payload;
    },
    setCouponForEdit: (state, action: any) => {
      console.log(action.payload  ,"setCouponForEdit" )
      state.CouponFormData = action.payload?.row.original;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doAddImages: (state, action: PayloadAction<{ data: any; callback: any }>) => {
      console.log('addimages');
      // state.phone = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    }, 
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSliceTodayActivity;
