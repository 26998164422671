export interface userInterface {
  _id: string;
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  englishFirstName: string;
  englishLastName: string;
  pincode: string;
  state: string;
  district: string;
  city: string;
  apartment: any;
  hasDog: HasDog;
  dogDetail: DogInterface;
  dogDetailList: DogInterface[];
  mobileNumber: string;
  status: UserStatusEnum;
}
export interface DogInterface {
  breedName: string;
  dateOfVaccine: string;
  dogName: string;
  file: string;
}
export enum HasDog {
  YES = 1,
  NO = 2
}
export enum UserStatusEnum {
  ACTIVE = 1,
  INACTIVE = 2
}
export enum GenderEnum {
  MALE = 1,
  FEMALE = 2
}
export interface userState {
  userList: userInterface[];
  userDetails: userInterface;
  pageNo: number;
  loading: boolean;
  pageSize: number;
  totalRow: number;
  search: string;
  cropShow: boolean;
  sortColumn: string;
  sortDir: string;
}

export type InitialMileStoneStata = userState;
