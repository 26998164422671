import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import {
  doGetBookingByIdRequestAPI,
  getBookingCancelById,
  getBookingDateList,
  getBookingList,
  getCancelBookingList,
  getPendingBookingList
} from 'utils copy/request';
import {
  SelectCancelSortColumn,
  SelectCancelSortDir,
  SelectPageNo,
  SelectPageNoCancel,
  SelectPageNoDateBooking,
  SelectPageNoPending,
  SelectPageSize,
  SelectPageSizeCancel,
  SelectPageSizeDateBooking,
  SelectPageSizePending,
  SelectPendingSortColumn,
  SelectPendingSortDir,
  SelectSearch,
  SelectSortColumn,
  SelectSortColumnDateBooking,
  SelectSortDir,
  SelectSortDirDateBooking,
  selectBookingCancelFormData,
  selectBookingDateFormData,
  selectBookingFormData,
  selectBookingPendingFormData
} from './selector';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { BookingCancelInterface, BookingDateInterface, BookingInterface, BookingPendingInterface } from '../types';
import moment from 'moment';

export function* doGetBookingListRequest() {
  yield delay(500);
  try {
    const form: BookingInterface = yield select(selectBookingFormData);
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      getBookingList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${form.startDate > 0 ? moment(form.startDate).format('YYYY-MM-DD') : ''}&endDate=${
        form.endDate > 0 ? moment(form.endDate).format('YYYY-MM-DD') : ''
      }${searchText.length > 0 ? '&search=' + searchText : ''}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setBookingList(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetBookingByIdRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Offer to booking',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(getBookingCancelById, action.payload.id);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield put(actions.setBookingForEdit(response.data.data));
    yield put(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetCancelBookingListRequest() {
  yield delay(500);
  try {
    const form: BookingCancelInterface = yield select(selectBookingCancelFormData);
    const pageNo: number = yield select(SelectPageNoCancel);
    const pageSize: number = yield select(SelectPageSizeCancel);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectCancelSortColumn);
    const SortDir: string = yield select(SelectCancelSortDir);
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      getCancelBookingList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${form.startDate > 0 ? moment(form.startDate).format('YYYY-MM-DD') : ''}&endDate=${
        form.endDate > 0 ? moment(form.endDate).format('YYYY-MM-DD') : ''
      }${searchText.length > 0 ? '&search=' + searchText : ''}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalCancelRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setCancelBookingList(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getBookingById(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select User',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doGetBookingByIdRequestAPI, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(actions.setBookingForEdit(response.data.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetPendingBookingListRequest() {
  yield delay(500);
  try {
    const form: BookingPendingInterface = yield select(selectBookingPendingFormData);
    const pageNo: number = yield select(SelectPageNoPending);
    const pageSize: number = yield select(SelectPageSizePending);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectPendingSortColumn);
    const SortDir: string = yield select(SelectPendingSortDir);
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      getPendingBookingList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${form.startDate > 0 ? moment(form.startDate).format('YYYY-MM-DD') : ''}&endDate=${
        form.endDate > 0 ? moment(form.endDate).format('YYYY-MM-DD') : ''
      }${searchText.length > 0 ? '&search=' + searchText : ''}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalPendingRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setPendingBookingList(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
//
export function* doGetBookingDateListRequest() {
  yield delay(500);
  try {
    const form: BookingDateInterface = yield select(selectBookingDateFormData);
    const pageNo: number = yield select(SelectPageNoDateBooking);
    const pageSize: number = yield select(SelectPageSizeDateBooking);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumnDateBooking);
    const SortDir: string = yield select(SelectSortDirDateBooking);
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      getBookingDateList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${form.startDate > 0 ? moment(form.startDate).format('YYYY-MM-DD') : ''}&endDate=${
        form.endDate > 0 ? moment(form.endDate).format('YYYY-MM-DD') : ''
      }${searchText.length > 0 ? '&search=' + searchText : ''}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalBookingDateRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setBookingDateList(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* profileRepoSaga() {
  yield takeLatest(actions.doGetBookingList, doGetBookingListRequest);
  yield takeLatest(actions.doGetCancelBookingList, doGetCancelBookingListRequest);
  yield takeLatest(actions.getBookingById, doGetBookingByIdRequest);
  yield takeLatest(actions.doBookingEdit, getBookingById);
  yield takeLatest(actions.doGetPendingBookingList, doGetPendingBookingListRequest);
  yield takeLatest(actions.doGetBookingDateList, doGetBookingDateListRequest);
}
