import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { GetRoleUserEditRequest, UpdateSchoolPasswordRequest } from 'utils copy/request';
import { RoleUserInterface, UpdatePasswordInterface } from '../types';
import { selectRoleUserForm, SelectUpdatePasswordFormData } from './selector';
import { openSnackbar } from 'store/reducers/snackbar';
export function* RoleUserAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: RoleUserInterface = yield select(selectRoleUserForm);

  if (form.email.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
  if (form.fullName.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
  if (form.confirmPassword.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
  if (form.phoneno.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
  if (form.username.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
}
export function* getUserListRequest() {
  yield delay(500);
}
export function* UserEditRequest(action: { payload: any }) {
  yield put(actions.setUserForEdit(action.payload));
}
export function* updateRoleuserRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: RoleUserInterface = yield select(selectRoleUserForm);

  // if (form.question.length === 0) {
  //   // toast.error("please fill Question");
  //   return;
  // }
  // if (form.answer.length === 0) {
  //   // toast.error("please fill Answer");
  //   return;
  // }
  try {
    const response: AxiosResponse = yield call(GetRoleUserEditRequest, form);
    if (response && !response.data) {
      // toast.error(response.data.message)
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];
          openSnackbar({
            open: true,
            message: element.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          });
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
      openSnackbar({
        open: true,
        message: error.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });
    }
  }
}
export function* UserPasswordUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: UpdatePasswordInterface = yield select(SelectUpdatePasswordFormData);

  try {
    const response: AxiosResponse = yield call(UpdateSchoolPasswordRequest, form);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.clearPasswordForm());
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    if (error.message !== "Cannot read properties of undefined (reading 'context')") {
      if (error.message !== 'call: argument fn is undefined or null') {
        if (error.response.status !== 401) {
          if (error.response) {
            if (error.response.data.valData) {
              let dataerrer = error.response.data.valData.errors.map((item: any) => {
                return { name: item.param, message: item.msg };
              });
              for (let index = 0; index < dataerrer.length; index++) {
                const element = dataerrer[index];
                yield put(
                  openSnackbar({
                    open: true,
                    message: element.message,
                    variant: 'alert',
                    alert: {
                      color: 'error'
                    },
                    close: false
                  })
                );
              }
            } else {
              yield put(
                openSnackbar({
                  open: true,
                  message: error.response.data.message,
                  variant: 'alert',
                  alert: {
                    color: 'error'
                  },
                  close: false
                })
              );
            }
          } else {
            yield put(
              openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                  color: 'error'
                },
                close: false
              })
            );
          }
        } else {
          localStorage.removeItem('token');
        }
      }
    }
  }
}
export function* authRepoSagaRoleUser() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.NewRoleUserAdded, RoleUserAddRequest);
  yield takeLatest(actions.doGetRoleUserList, getUserListRequest);
  yield takeLatest(actions.userEdit, UserEditRequest);
  yield takeLatest(actions.updateDataRoleUser, updateRoleuserRequest);
  yield takeLatest(actions.doUpdateUserPassword, UserPasswordUpdateRequest);
}

// questionAddRequestMain
